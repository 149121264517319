import ExoStepper, { FinalPage } from "../exo/ExoStepper";
import { useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import { API_POST, DBRequest, useConfig } from "../../api/api";
import StepContractTemplate from "../steps/StepContractTemplate";
import * as Yup from "yup";
import StepContractVars from "../steps/StepContractVars";
import { useTranslation } from "react-i18next";
import ExoDialog from "../exo/ExoDialog";
import StepDelivery from "../steps/StepDelivery";
import StepUser from "../steps/StepUser";

const NewWorkContract = ({ onCreate, onClose, open, startData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const steps = [
    {
      label: t("Contract Template"),
      content: ({
        dataCollection,
        updateValidation,
        updateDataCollection,
        activeStep,
        setActiveStep,
      }) => (
        <StepContractTemplate
          type="employeeContract"
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: t("Contract Info"),
      content: ({
        dataCollection,
        updateValidation,
        updateDataCollection,
        activeStep,
        setActiveStep,
      }) => (
        <StepContractVars
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: t("Employee"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepUser
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          type="user"
        />
      ),
    },
    {
      label: t("Delivery"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepDelivery
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          recipientKey="user"
        />
      ),
    },
  ];
  const config = useConfig();
  const finalStep = {
    operation: ({
      dataCollection,
      handleResponse,
      setIsLoading,
      handleError,
    }) => {
      DBRequest({
        config,
        path: "employee-contracts",
        method: API_POST(dataCollection),
        onResponse: (data) => {
          handleResponse(data);
          onCreate(data);
        },
        onLoading: setIsLoading,
        onError: handleError,
      });
    }, // your operation with the data
    content: (response) => <FinalPage response={response} />, // component with final operations and the feedback
  };

  return (
    <ExoDialog open={open}>
      <ExoStepper
        onClose={onClose}
        data={steps}
        finalStep={finalStep}
        startDataCollection={startData}
      />
    </ExoDialog>
  );
};

export default NewWorkContract;
