import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { Box, Button } from "@mui/material";
import TemplatePreview from "../templates/TemplatePreview";
import { useTranslation } from "react-i18next";
import TemplateVarSwitcher from "../templates/TemplateVarSwitcher";
import { numberToString } from "../special/numberConverter";
import moment from "moment";
import { useDebounce } from "use-debounce";

const ExoDocumentPreview = ({
  dataCollection,
  previewDocument,
  documentId,
  width = 500,
}) => {
  const [template, setTemplate] = useState(null);
  const [templateChange, setTemplateChange] = useState(0);
  const [templateSkonto, setTemplateSkonto] = useState("");
  const [templatePositions, setTemplatePositions] = useState("");
  const [debouncedDataCollection] = useDebounce(dataCollection, 700);
  const { t } = useTranslation();
  const config = useConfig();

  useEffect(() => {
    if (!config) return;
    setTemplate(null);
    DBRequest({
      config,
      path: documentId
        ? `templates/${documentId}`
        : `templates?templateType[eq]=${previewDocument}`,
      method: API_GET,
      onResponse: (data) =>
        handleResponse(documentId ? data.data : data.data[0]),
    });
  }, [config, previewDocument, documentId]);

  function handleResponse(data) {
    var newTemplate = data;
    var templateVars = newTemplate.vars;

    // POSITIONS -------------------------------
    // Your start and end keys
    const positionsStartKey = "%%positionRowStart%%";
    const positionsEndKey = "%%positionRowEnd%%";
    const positionsReplaceKey = "%%documentPositions%%";
    const positionsTemplateArea = getTemplateArea(
      newTemplate.content,
      positionsStartKey,
      positionsEndKey,
      positionsReplaceKey
    );
    newTemplate.content = positionsTemplateArea.modifiedContent;
    // Add the custom positions var to the var array of the template
    const customPositionsVar = {
      value: positionsReplaceKey,
      name: "Document Positions",
      preview: positionsTemplateArea.contentBetweenKeys[0],
    };
    templateVars.push(customPositionsVar);
    setTemplatePositions(positionsTemplateArea.contentBetweenKeys[0]);

    // SKONTO --------------------------------
    const skontoStartKey = "%%discountAreaStart%%";
    const skontoEndKey = "%%discountAreaEnd%%";
    const skontoReplaceKey = "%%documentSkonto%%";
    const skontoTemplateArea = getTemplateArea(
      newTemplate.content,
      skontoStartKey,
      skontoEndKey,
      skontoReplaceKey
    );
    newTemplate.content = skontoTemplateArea.modifiedContent;
    const customSkontoVar = {
      value: skontoReplaceKey,
      name: "Document Skonto",
      preview: "",
    };
    templateVars.push(customSkontoVar);
    setTemplateSkonto(
      `${skontoStartKey}${skontoTemplateArea.contentBetweenKeys}${skontoEndKey}`
    );
    // save template
    setTemplate(newTemplate);
    setTemplateChange(templateChange + 1);
  }

  // change templateVars to the dataCollections data
  function prepairTemplateVars(varsToPrepair) {
    if (!varsToPrepair) return;
    const valueKeyMap = getTrueTemplateVarValue(dataCollection);
    setTemplate({
      ...template,
      vars: replaceTemplateVars(
        varsToPrepair,
        valueKeyMap,
        dataCollection.positions || []
      ),
    });
  }

  function handleDynamicElements() {
    if (template.vars.length) {
      const templateSections = [
        "headerP1",
        "headerP2",
        "content",
        "footerP1",
        "footerP2",
      ];
      var updatedTemplate = { ...template };
      templateSections.forEach((section) => {
        // enable deliveryRange or deliveryDate
        if (
          dataCollection.useDeliveryDateRange ||
          dataCollection.deliveryDateStart
        ) {
          // remove deliveryDate
          const startKey = "%%deliveryDateAreaStart%%";
          const endKey = "%%deliveryDateAreaEnd%%";
          const replaceKey = "";

          const deliveryDateTemplateArea = getTemplateArea(
            template[section],
            startKey,
            endKey,
            replaceKey
          );
          updatedTemplate[section] = deliveryDateTemplateArea.modifiedContent;
        } else {
          // remove deliveryDateRange
          const startKey = "%%deliveryDateRangeAreaStart%%";
          const endKey = "%%deliveryDateRangeAreaEnd%%";
          const replaceKey = "";

          const deliveryDateRangeTemplateArea = getTemplateArea(
            template[section],
            startKey,
            endKey,
            replaceKey
          );
          updatedTemplate[section] =
            deliveryDateRangeTemplateArea.modifiedContent;
        }

        // add or remove the skonto section
        if (Boolean(parseInt(dataCollection.discount))) {
          // add skonto section
          const skontoContentVars = [
            {
              value: "%%documentSkonto%%",
              name: "",
              preview: templateSkonto,
            },
          ];
          const newContent = TemplateVarSwitcher(
            updatedTemplate[section],
            skontoContentVars
          );
          updatedTemplate[section] = newContent;
        } else {
          // remove skonto section
          const skontoStartKey = "%%discountAreaStart%%";
          const skontoEndKey = "%%discountAreaEnd%%";
          const skontoReplaceKey = "%%documentSkonto%%";

          const skontoTemplateArea = getTemplateArea(
            updatedTemplate[section],
            skontoStartKey,
            skontoEndKey,
            skontoReplaceKey
          );
          updatedTemplate[section] = skontoTemplateArea.modifiedContent;
        }
      });
      setTemplate({ ...updatedTemplate });
    }
  }

  useEffect(() => {
    if (template && templatePositions) prepairTemplateVars(template.vars);
  }, [debouncedDataCollection, templatePositions, templateChange]);

  useEffect(() => {
    if (!template) return;
    handleDynamicElements();
  }, [debouncedDataCollection]);

  function getTrueTemplateVarValue(dataCollection = {}) {
    // positions sum template Vars
    var nettoSum = 0;
    var bruttoSum = 0;
    if (dataCollection.positions) {
      dataCollection.positions.forEach((pos) => {
        if (pos.isOptional) return;
        nettoSum += pos.price * pos.amount;
        bruttoSum += pos.price * pos.amount * (1 + pos.taxRate / 100);
      });
    }
    // dates
    const dateFormat = config.general.dateOutputFormat;
    const discountDeadline = moment()
      .add(dataCollection.discountPaymentDeadlineInDays, "days")
      .format(dateFormat);
    const paymentDeadline = moment()
      .add(dataCollection.paymentDeadlineInDays, "days")
      .format(dateFormat);
    // assign values to the right key/placeholder
    const valueKeyMap = {
      "%%paymentDeadlineDate%%": {
        value: paymentDeadline,
        fallback: "01.01.2022",
      },
      "%%paymentDeadlineDays%%": {
        value: dataCollection.paymentDeadlineInDays,
        fallback: "01.01.2022",
      },
      "%%positionsSumBrutto%%": {
        value: numberToString(bruttoSum),
        fallback: "0.00",
      },
      "%%positionsSumNetto%%": {
        value: numberToString(nettoSum),
        fallback: "0.00",
      },
      "%%positionsSumTax%%": {
        value: numberToString(bruttoSum - nettoSum),
        fallback: "0.00",
      },
      "%%documentPositions%%": {
        value: generateRenderedTemplatePosition(
          templatePositions,
          dataCollection
        ),
        fallback: "",
      },
      "%%documentDate%%": {
        value: moment(dataCollection.date).format(dateFormat),
        fallback: t("Empty"),
      },
      "%%documentNr%%": {
        value: dataCollection.nr,
        fallback: t("*AUTOMATIC*"),
      },
      "%%documentTitle%%": {
        value: dataCollection.title,
        fallback: t("Empty"),
      },
      "%%documentNote%%": {
        value: dataCollection.note,
        fallback: "",
      },
      "%%documentValidUntil%%": {
        value: moment(dataCollection.validUntil).format(dateFormat),
        fallback: t("Empty"),
      },
      "%%documentContactPersonSalutation%%": {
        value: dataCollection.contactPerson
          ? dataCollection.contactPerson.salutation
          : null,
        fallback: t("Empty"),
      },
      "%%documentContactPersonFirstName%%": {
        value: dataCollection.contactPerson
          ? dataCollection.contactPerson.firstName
          : null,
        fallback: t("Empty"),
      },
      "%%documentContactPersonLastName%%": {
        value: dataCollection.contactPerson
          ? dataCollection.contactPerson.lastName
          : null,
        fallback: t("Empty"),
      },
      "%%documentContactPersonEmail%%": {
        value: dataCollection.contactPerson
          ? dataCollection.contactPerson.email
          : null,
        fallback: t("Empty"),
      },
      "%%documentContactPersonTelephoneNumber%%": {
        value: dataCollection.contactPerson
          ? dataCollection.contactPerson.phone
          : null,
        fallback: t("Empty"),
      },
      "%%recipientClientNumber%%": {
        value: dataCollection.recipient ? dataCollection.recipient.nr : "",
        fallback: t("Empty"),
      },
      "%%recipientCompanyName%%": {
        value: dataCollection.recipient ? dataCollection.recipient.name : "",
        fallback: t("Empty"),
      },
      "%%recipientCompanyNameShort%%": {
        value: dataCollection.recipient
          ? dataCollection.recipient.nameShort
          : "",
        fallback: t("Empty"),
      },
      "%%recipientWebsiteUrl%%": {
        value: dataCollection.recipient ? dataCollection.recipient.url : "",
        fallback: t("Empty"),
      },
      "%%recipientIban%%": {
        value: dataCollection.recipient ? dataCollection.recipient.iban : "",
        fallback: t("Empty"),
      },
      "%%recipientInvoiceMail%%": {
        value: dataCollection.recipient
          ? dataCollection.recipient.invoiceMail
          : "",
        fallback: t("Empty"),
      },
      "%%recipientContactMail%%": {
        value: dataCollection.recipient ? dataCollection.recipient.mail : "",
        fallback: t("Empty"),
      },
      "%%recipientPhoneNumber%%": {
        value: dataCollection.recipient ? dataCollection.recipient.phone : "",
        fallback: t("Empty"),
      },
      "%%recipientStreet%%": {
        value: dataCollection.recipient
          ? dataCollection.recipient.address.street
          : "",
        fallback: t("Empty"),
      },
      "%%recipientHouseNumber%%": {
        value: dataCollection.recipient
          ? dataCollection.recipient.address.houseNumber
          : "",
        fallback: "",
      },
      "%%recipientZipCode%%": {
        value: dataCollection.recipient
          ? dataCollection.recipient.address.zipCode
          : "",
        fallback: "",
      },
      "%%recipientCity%%": {
        value: dataCollection.recipient
          ? dataCollection.recipient.address.city
          : "",
        fallback: t("Empty"),
      },
      "%%recipientCountry%%": {
        value: dataCollection.recipient
          ? dataCollection.recipient.address.country
          : "",
        fallback: t("Empty"),
      },
      "%%recipientAddressAddition%%": {
        value: dataCollection.recipient
          ? dataCollection.recipient.address.addition
          : "",
        fallback: "",
      },
      "%%recipientSalutation%%": {
        value: dataCollection.recipient
          ? dataCollection.recipient.salutation
          : "",
        fallback: t("Empty"),
      },
      "%%recipientFirstName%%": {
        value: dataCollection.recipient
          ? dataCollection.recipient.firstName
          : "",
        fallback: t("Empty"),
      },
      "%%recipientLastName%%": {
        value: dataCollection.recipient
          ? dataCollection.recipient.lastName
          : "",
        fallback: t("Empty"),
      },
      "%%cashDiscountRate%%": {
        value: dataCollection.discountRate,
        fallback: "",
      },
      "%%discountedPrice%%": {
        value: numberToString(
          bruttoSum - (dataCollection.discountRate / 100) * bruttoSum
        ),
        fallback: "",
      },
      "%%cashDiscountDaysToPay%%": {
        value: dataCollection.discountPaymentDeadlineInDays,
        fallback: "",
      },
      "%%documentDeliveryStartDate%%": {
        value: moment(dataCollection.deliveryDateStart).format(dateFormat),
        fallback: "",
      },
      "%%documentDeliveryEndDate%%": {
        value: moment(dataCollection.deliveryDateEnd).format(dateFormat),
        fallback: "",
      },
      "%%cashDiscountDeadline%%": {
        value: discountDeadline,
        fallback: "",
      },
    };
    return valueKeyMap;
  }

  return (
    <Box
      className="w-full h-full flex flex-col  justify-center items-center"
      sx={{
        "& .templateVar": {
          animation: "templateVarChange 5s ease",
          "& p, h1, h2, h3, h4, td": {
            animation: "templateVarChange 5s ease",
          },
        },
        "@keyframes templateVarChange": {
          "0%": {
            color: "red",
          },
          "100%": {
            color: "initial",
          },
        },
        "& .templateVarFallback": {
          color: "red",
        },
      }}
    >
      {template !== null && (
        <TemplatePreview
          data={template}
          paginate={false}
          fit
          className="flex h-full justify-center items-center"
        />
      )}
    </Box>
  );
};

function getTemplateArea(content, startKey, endKey, replacerKey) {
  // Use a global regular expression to match all areas between startKey and endKey
  const regex = new RegExp(`${startKey}(.*?)${endKey}`, "gs"); // 's' flag allows dot (.) to match newline characters
  let contentBetweenKeys = [];

  // Replace all matches and store the matched content between the keys
  const modifiedContent = content.replace(regex, (match, group1) => {
    contentBetweenKeys.push(group1); // Store the content between start and end keys
    return replacerKey; // Replace the matched area with replacerKey
  });

  return { modifiedContent, contentBetweenKeys };
}

function generateRenderedTemplatePosition(template, dataCollection) {
  var renderedTemplatePositions = "";
  if (dataCollection.positions) {
    dataCollection.positions.forEach((element) => {
      const positionVarsMap = {
        "%%positionPosition%%": {
          value: element.position,
          fallback: "",
        },
        "%%positionName%%": {
          value: element.name,
          fallback: "",
        },
        "%%positionAmount%%": {
          value: element.isGroup ? "" : numberToString(element.amount),
          fallback: "",
        },
        "%%positionUnit%%": {
          value: element.isGroup ? "" : element.unit,
          fallback: "",
        },
        "%%positionPrice%%": {
          value: element.isGroup ? "" : numberToString(element.price),
          fallback: "",
        },
        "%%positionSumBrutto%%": {
          value: element.isGroup
            ? ""
            : numberToString(
                element.price * element.amount * (1 + element.taxRate / 100)
              ),
          fallback: "",
        },
        "%%positionSumNetto%%": {
          value: element.isGroup
            ? ""
            : numberToString(element.price * element.amount),
          fallback: "",
        },
        "%%positionSumTax%%": {
          value: element.isGroup
            ? ""
            : numberToString(element.price * (element.taxRate / 100)),
          fallback: "",
        },
        "%%positionTaxationRate%%": {
          value: element.isGroup ? "" : element.taxRate,
          fallback: "",
        },
        "%%positionDescription%%": {
          value: element.isGroup ? "" : element.description,
          fallback: "",
        },
        "%%positionImage%%": {
          value: element.isGroup
            ? ""
            : element.image
            ? element.image.temporaryUrl
            : "",
          fallback: "",
        },
      };
      const positionTemplateVars = [
        {
          name: "",
          value: "%%positionPosition%%",
          preview: "",
        },
        {
          name: "",
          value: "%%positionName%%",
          preview: "",
        },
        {
          name: "",
          value: "%%positionAmount%%",
          preview: "",
        },
        {
          name: "",
          value: "%%positionUnit%%",
          preview: "",
        },
        {
          name: "",
          value: "%%positionPrice%%",
          preview: "",
        },
        {
          name: "",
          value: "%%positionSumBrutto%%",
          preview: "",
        },

        {
          name: "",
          value: "%%positionSumNetto%%",
          preview: "",
        },
        {
          name: "",
          value: "%%positionSumTax%%",
          preview: "",
        },

        {
          name: "",
          value: "%%positionTaxationRate%%",
          preview: "",
        },
        {
          name: "",
          value: "%%positionDescription%%",
          preview: "",
        },
        {
          name: "",
          value: "%%positionImage%%",
          preview: "",
          noHighlight: true,
        },
        {
          name: "",
          value: "%%positionImageAreaStart%%",
          preview: "",
          noHighlight: true,
        },
        {
          name: "",
          value: "%%positionImageAreaEnd%%",
          preview: "",
          noHighlight: true,
        },
        {
          name: "",
          value: "%%positionOptionalAdditionStart%%",
          preview: "",
          noHighlight: true,
        },
        {
          name: "",
          value: "%%positionOptionalAdditionEnd%%",
          preview: "",
          noHighlight: true,
        },
      ];

      // remove position is optional addition
      var templateToRender = template;

      if (!element.isOptional) {
        const startKey = "%%positionOptionalAdditionStart%%";
        const endKey = "%%positionOptionalAdditionEnd%%";
        const replaceKey = "";

        const imageTemplateArea = getTemplateArea(
          templateToRender,
          startKey,
          endKey,
          replaceKey
        );
        templateToRender = imageTemplateArea.modifiedContent;
      }

      // remove position img if empty
      if (!element.image) {
        const startKey = "%%positionImageAreaStart%%";
        const endKey = "%%positionImageAreaEnd%%";
        const replaceKey = "";

        const imageTemplateArea = getTemplateArea(
          templateToRender,
          startKey,
          endKey,
          replaceKey
        );
        templateToRender = imageTemplateArea.modifiedContent;
      }

      // remove descripiton segmentation
      const startKey = "%%positionDescriptionFragmentStart%%";
      const endKey = "%%positionDescriptionFragmentEnd%%";
      const replaceKey = "";

      const imageTemplateArea = getTemplateArea(
        templateToRender,
        startKey,
        endKey,
        replaceKey
      );
      templateToRender = imageTemplateArea.modifiedContent;

      renderedTemplatePositions += TemplateVarSwitcher(
        templateToRender,
        replaceTemplateVars(positionTemplateVars, positionVarsMap)
      );
    });
  }
  return renderedTemplatePositions;
}

function replaceTemplateVars(templateVars, varsMap, positions = []) {
  const newTemplateVars = [];

  // normal template Vars
  templateVars.forEach((element) => {
    if (varsMap[element.value]) {
      var updatedVar = element;

      if (element.noHighlight) {
        updatedVar.preview = varsMap[element.value].value;
      } else {
        if (varsMap[element.value].value) {
          updatedVar.preview = `<div class="templateVar" style=" display: inline; font-family: inherit;
    font-size: inherit;
    line-height: inherit; text-align: inherit;">${
      varsMap[element.value].value
    }</div>`;
        } else {
          updatedVar.preview = `<div class="templateVarFallback" style=" display: inline; font-family: inherit;
        font-size: inherit;
        line-height: inherit;  text-align: inherit;">${
          varsMap[element.value].fallback
        }</div>`;
        }
      }
      newTemplateVars.push(updatedVar);
    } else {
      newTemplateVars.push(element);
    }
  });
  return newTemplateVars;
}

export default ExoDocumentPreview;
