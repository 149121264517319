import {
  useTheme,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import Feedback from "../../components/special/Feedback";
import { API_PATCH, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import TextCard from "../../components/cards/TextCard";
import DisplayPositions from "../../components/preview/PreviewPositions";
import PictureNameCard from "../../components/cards/PictureNameCard";
import FileCard from "../../components/cards/FilesCard";
import DownloadIcon from "@mui/icons-material/Download";
import CardContainer from "../../components/cards/layout/CardContainer";
import { useTranslation } from "react-i18next";
import SkeletonCard from "../../components/cards/SkeletonCard";
import ExoResendMail from "../../components/exo/ExoResendMail";
import DateLabel from "../../components/label/DateLabel";
import CardGrid from "../../components/cards/layout/CardGrid";
import useFetch from "../../api/useFetch";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import SpeedDialMenu from "../../components/menu/SpeedDialMenu";
import NewOrder from "../../components/new/NewOrder";
import PopUpContainer from "../../components/popup/PopUpContainer";
import Share from "../../components/special/Share";
import { ExoAttachments } from "../../components/exo/ExoAttachments";
import moment from "moment";
import { PositionName } from "../../components/select/SelectPositions";

const ExtendOrder = ({ row, onUpdate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const { apiData, setApiData } = useFetch(`orders/${row.id}`);

  const [status, setStatus] = useState(0);
  const [open, setOpen] = useState(false);
  const [startData, setStartData] = useState(null);

  const columns = [
    {
      label: t("Position"),
      key: "position",
      align: "left",
      hiddenMobile: false,
      type: "number",
      width: "48px",
    },
    {
      label: t("Description"),
      key: "name",
      align: "left",
      hiddenMobile: false,
      type: "custom",
      width: "auto",
      cell: (row) => <PositionName element={row} />,
    },
    {
      label: t("Quantity"),
      key: "amount",
      align: "center",
      hiddenMobile: false,
      type: "number",
      width: "80px",
    },
  ];

  function handleChange(e) {
    setStatus(e.target.value);
    if (e.target.value !== status) updateStatusInDB(e.target.value);
  }

  function updateStatusInDB(newStatus) {
    const request = { status: newStatus };

    DBRequest({
      config,
      path: `orders/${row.id}/status`,
      method: API_PATCH(request),
      onResponse: () => {
        onUpdate({ ...row, status: newStatus });
      },
      onError: () => setStatus(row.status),
      onFeedback: handleAlertOpen,
    });
  }

  // menu
  const actions = [
    {
      icon: <ModeEditIcon />,
      name: t("Edit"),
      onClick: handleEdit,
    },
    {
      icon: <ContentCopyIcon />,
      name: t("Copy"),
      onClick: handleCopy,
    },
    {
      icon: <ForwardToInboxIcon />,
      name: t("Send Again"),
      onClick: () => setOpenResendMail(true),
    },
    {
      icon: <PrintIcon />,
      name: t("Print"),
      onClick: () => handlePrint(apiData && apiData.data.file.temporaryUrl),
      hidden: apiData && apiData.data.file ? false : true,
    },
    {
      icon: <ShareIcon />,
      name: t("Share"),
      onClick: () => setOpenShare(true),
    },
  ];

  function handleEdit() {
    setStartData(apiData.data);
    setOpen(true);
  }
  function handleCopy() {
    const dataCopied = {};
    dataCopied.positions = apiData.data.positions;
    dataCopied.note = apiData.data.note;
    dataCopied.title = apiData.data.title;
    dataCopied.startDate = moment().format();
    dataCopied.deliveryDate = moment().format();

    setStartData(dataCopied);
    setOpen(true);
  }
  function handleUpdate(data) {
    setApiData(data);
    onUpdate(data.data);
  }

  // share
  const [openShare, setOpenShare] = useState(false);
  function handleCloseShare() {
    setOpenShare(!openShare);
  }

  // print
  function handlePrint(pdfUrl) {
    // Open a new window or iframe with the PDF URL
    const printWindow = window.open(pdfUrl, "_blank");

    // Wait for the PDF to load
    printWindow.onload = () => {
      // Trigger the print dialog
      printWindow.print();
    };
  }

  // resend mail
  const [openResendMail, setOpenResendMail] = useState(false);

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return (
    <Box className="flex flex-col gap-4 relative overflow-y-auto">
      {!apiData ? (
        <OrderSkeleton />
      ) : (
        <>
          <CardGrid>
            <ExoResendMail
              recipient={apiData.data.recipient}
              type="orders"
              id={apiData.data.id}
              open={openResendMail}
              onClose={() => setOpenResendMail(false)}
            />
            <SpeedDialMenu actions={actions} />
            <TextCard text={apiData.data.title} span={4} />
            <PictureNameCard
              title={t("Recipient")}
              pictureUrl={
                apiData.data.recipient.logo &&
                apiData.data.recipient.logo.thumbnailUrl
              }
              name={
                apiData.data.recipient.firstName
                  ? apiData.data.recipient.firstName +
                    " " +
                    apiData.data.recipient.lastName
                  : apiData.data.recipient.name
              }
              colorPool={apiData.data.colorPool}
            />
            <CardContainer>
              <Box className="flex flex-col justify-center items-center gap-2 p-4 rounded-lg">
                <Box className="flex flex-row gap-2">
                  <Typography variant="p">{t("Order Nr")}</Typography>
                  <Typography variant="h5" className=" monospace">
                    {apiData.data.nr}
                  </Typography>
                </Box>
                <Box className="flex flex-row gap-2">
                  <Typography variant="p">{t("Start Date")}</Typography>
                  <Typography variant="h5" className="monospace">
                    <DateLabel date={apiData.data.startDate} />
                  </Typography>
                </Box>
                <Box className="flex flex-row gap-2">
                  <Typography variant="p">{t("Delivery Date")}</Typography>
                  <Typography variant="h5" className="monospace">
                    <DateLabel date={apiData.data.deliveryDate} />
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <InputLabel id="status-select-label">
                    {t("Status")}
                  </InputLabel>
                  <Select
                    labelId="status-select-label"
                    id="status-select"
                    value={status}
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>{t("Running")}</MenuItem>
                    <MenuItem value={1}>{t("Finished")}</MenuItem>
                    <MenuItem value={2}>{t("Cancled")}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </CardContainer>

            <FileCard
              files={[
                {
                  label: t("Order"),
                  icon: <DownloadIcon />,
                  url: apiData.data.file && apiData.data.file.temporaryUrl,
                },
              ]}
            />

            <PictureNameCard
              title={t("Creator")}
              pictureUrl={
                apiData.data.authorPicture &&
                apiData.data.authorPicture.thumbnailUrl
              }
              name={apiData.data.author}
              colorPool={apiData.data.colorPool}
            />
            <CardContainer>
              <ExoAttachments attachments={apiData.data.attachments} />
            </CardContainer>
          </CardGrid>
          <CardContainer
            sx={{
              height: "100vh-600px",
              display: "block",
              padding: "0px",
            }}
            span={999}
          >
            <DisplayPositions
              positions={apiData.data.positions}
              showSum={false}
              columns={columns}
            />
          </CardContainer>
          {apiData.data.notice && (
            <CardContainer>{apiData.data.notice}</CardContainer>
          )}
        </>
      )}
      <NewOrder
        open={open}
        onUpdate={handleUpdate}
        onClose={() => setOpen(false)}
        startData={startData}
        type={Boolean(startData) ? "custom" : null}
      />
      <PopUpContainer open={openShare} onClose={handleCloseShare}>
        <Share
          title={`${t("Share Order")} ${apiData && apiData.data.nr}`}
          url={
            apiData && apiData.data.file ? apiData.data.file.temporaryUrl : ""
          }
        />
      </PopUpContainer>
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

const OrderSkeleton = () => {
  return (
    <CardGrid>
      <SkeletonCard height={54} span={4} />
      <SkeletonCard height={188} />
      <SkeletonCard height={188} />
      <SkeletonCard height={188} />
      <SkeletonCard height={188} />
      <SkeletonCard height={188} />
      <SkeletonCard height={120} span={4} />
    </CardGrid>
  );
};

export default ExtendOrder;
