import { tokens } from "../../global/theme/tokens";
import { useTheme, Box } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useTranslation } from "react-i18next";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";

export const ContractStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t("Open")} variant="open" />;
    case 1:
      return <Status text={t("Accepted")} variant="accepted" />;
    case 2:
      return <Status text={t("Rejected")} variant="rejected" />;
  }
};
export const OfferStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t("Sent")} variant="sent" />;
    case 1:
      return <Status text={t("Accepted")} variant="accepted" />;
    case 2:
      return <Status text={t("Finished")} variant="finished" />;
    case 3:
      return <Status text={t("Rejected")} variant="rejected" />;
    case 4:
      return <Status text={t("Created")} variant="open" />;
  }
};

export const MailStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t("Queued")} variant="qued" />;
    case 1:
      return <Status text={t("Sent")} variant="accepted" />;
    case 2:
      return <Status text={t("Failed")} variant="rejected" />;
  }
};

export const LetterStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t("Created")} variant="accepted" />;
    case 1:
      return <Status text={t("Sent")} variant="sent" />;
    case 2:
      return <Status text={t("Declined")} variant="rejected" />;
  }
};

export const InvoiceStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t("Created")} variant="open" />;
    case 1:
      return <Status text={t("Payed")} variant="accepted" />;
    case 2:
      return <Status text={t("Sent")} variant="sent" />;
    case 3:
      return <Status text={t("In Arrears")} variant="expired" />;
    case 4:
      return <Status text={t("Refusal to Pay")} variant="rejected" />;
  }
};

export const RequestStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t("Pending")} variant="open" />;
    case 1:
      return <Status text={t("Accepted")} variant="accepted" />;
    case 2:
      return <Status text={t("Declined")} variant="rejected" />;
  }
};

export const OrderStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t("Running")} variant="open" />;
    case 1:
      return <Status text={t("Finished")} variant="finished" />;
    case 2:
      return <Status text={t("Cancled")} variant="rejected" />;
    case 3:
      return <Status text={t("Sent")} variant="sent" />;
  }
};

export const ProjectStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t("Running")} variant="open" />;
    case 1:
      return <Status text={t("Finished")} variant="finished" />;
    case 2:
      return <Status text={t("Cancled")} variant="rejected" />;
  }
};

export const PaymentReminderStatus = ({ number }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  switch (number) {
    case 0:
      return <Status text={t("Ready")} variant="draft" />;
    case 1:
      return <Status text={t("Running")} variant="open" />;
    case 2:
      return <Status text={t("Paused")} variant="expired" />;
    case 3:
      return <Status text={t("Finished")} variant="finished" />;
  }
};

export const Status = ({ text, variant }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  var icon = <EmailIcon />;
  var color = colors.primary[600];
  switch (variant) {
    case "draft":
      icon = <ModeEditIcon />;
      color = colors.blueAccent[600];
      break;
    case "open":
      icon = <HourglassFullIcon />;
      color = colors.blueAccent[600];
      break;
    case "sent":
      icon = <EmailIcon />;
      color = colors.blueAccent[600];
      break;
    case "accepted":
      icon = <CheckCircleIcon />;
      color = colors.greenAccent[600];
      break;
    case "rejected":
      icon = <CancelIcon />;
      color = colors.redAccent[600];
      break;
    case "expired":
      icon = <QueryBuilderIcon />;
      color = colors.redAccent[600];
      break;
    case "finished":
      icon = <FlagCircleIcon />;
      color = colors.grey[600];
      break;
    case "legal":
      icon = <GavelOutlinedIcon />;
      color = colors.redAccent[500];
      break;
  }

  return (
    <Box
      className="flex flex-row gap-2 rounded-lg md:rounded-full px-2 md:pl-2 md:pr-3 py-1 w-fit md:w-full"
      sx={{ bgcolor: color + "25", color: color }}
    >
      {icon}
      <Box className="hidden md:block">{text}</Box>
    </Box>
  );
};
