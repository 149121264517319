import { Box, IconButton, Typography } from "@mui/material";
import DataModule from "../DataModules";
import NoDataAnimation from "../../../components/animation/NoDataAnimation";
import { useTranslation } from "react-i18next";
import CardContainer from "../../../components/cards/layout/CardContainer";
import MoneyLabel from "../../../components/label/MoneyLabel";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import HourLabel from "../../../components/label/HourLabel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { ExoTableToolbarItem } from "../../../components/exo/ExoTableToolbar";
import { hasPermissions } from "../../../auth/hasPermissions";

const EmployeeContract = ({ employeeInfo, onOpen }) => {
  const { t } = useTranslation();

  if (!employeeInfo.contract) {
    return (
      <Box className="w-full h-full flex justify-center items-center relative">
        {hasPermissions("employee-contract", "write") && (
          <ExoTableToolbarItem
            className=" absolute top-2 left-2"
            icon={<HistoryEduIcon />}
            text={t("New Contract")}
            onClick={onOpen}
          />
        )}
        <NoDataAnimation />
      </Box>
    );
  }

  const dataModules = [
    {
      type: "text",
      label: t("Description"),
      value: employeeInfo.contract.description,
    },
    {
      type: "text",
      label: t("Full Time"),
      value: employeeInfo.contract.fullTime ? t("yes") : t("no"),
    },
    {
      type: "text",
      label: t("Main Job"),
      value: employeeInfo.contract.mainJob ? t("yes") : t("no"),
    },
    {
      type: "text",
      label: t("Pay Type"),
      value: employeeInfo.contract.payType ? t("per Hour") : t("flat"),
    },
    {
      type: "text",
      label: t("Position"),
      value: employeeInfo.contract.position,
    },
    {
      type: "date",
      label: t("Start Date"),
      value: employeeInfo.contract.startDate,
    },
    {
      type: "date",
      label: t("Valid Until"),
      value: employeeInfo.contract.validUntil
        ? employeeInfo.contract.validUntil
        : "unlimited",
    },
    {
      type: "button",
      label: t("Signed Contract"),
      value: employeeInfo.contract.signedContractId,
    },
  ];

  return (
    <Box className="flex flex-col gap-3 items-start w-full relative">
      <ExoTableToolbarItem
        icon={<HistoryEduIcon />}
        text={t("New Contract")}
        onClick={onOpen}
      />
      <Box className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 w-full gap-4">
        <InfoCard
          label={t("Salery")}
          Icon={AttachMoneyIcon}
          value={employeeInfo.contract.salary}
          type="money"
        />
        <InfoCard
          label={t("Hours per Week")}
          Icon={AccessTimeIcon}
          value={employeeInfo.contract.hoursPerWeek}
          type="hours"
        />
        <InfoCard
          label={t("Vacation")}
          Icon={AccessTimeIcon}
          value={employeeInfo.contract.vacation}
        />
      </Box>
      {dataModules.map((display, index) => (
        <DataModule
          key={index}
          type={display.type}
          label={display.label}
          value={display.value}
        />
      ))}
    </Box>
  );
};

const InfoCard = ({ Icon, label, value, type }) => {
  return (
    <CardContainer className="flex flex-col gap-2 justify-center items-center">
      <Icon />
      <Typography textAlign="center">{label}</Typography>
      <Typography className="monospace" variant="h3" textAlign="center">
        {type === "money" && <MoneyLabel money={value || 0} />}
        {type === "hours" && <HourLabel minutes={value * 60} />}
        {!type && value}
      </Typography>
    </CardContainer>
  );
};

export default EmployeeContract;
