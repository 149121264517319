import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useEffect } from "react";
import { ColorModeContext } from "./theme/theme";
import { Link, NavLink } from "react-router-dom";
import { API_PATCH, DBRequest, useConfig } from "../api/api";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Notifications from "./Notifications";
import Cookies from "universal-cookie";
import { hasPermissions } from "../auth/hasPermissions";
import { ExoSmartSearch } from "../components/exo/ExoSmartSearch";
import { tokens } from "./theme/tokens";
import { TimeBadge } from "./TimeBadge";
import ExoAvatar from "../components/exo/ExoAvatar";
const Item = ({ to, icon }) => {
  return (
    <Link to={to}>
      <IconButton>{icon}</IconButton>
    </Link>
  );
};

const Topbar = ({ noAccountSettings = true }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const colorMode = useContext(ColorModeContext);
  const cookies = new Cookies();
  const config = useConfig();

  const firstName = cookies.get("firstName");
  const lastName = cookies.get("lastName");
  const profilePicture = cookies.get("profilePicture");
  const color = cookies.get("color");

  useEffect(() => {
    if (cookies.get("colorMode") === theme.palette.mode) return;

    cookies.set("colorMode", theme.palette.mode, {
      path: "/",
    });
    DBRequest({
      config,
      path: "userpreferences/own",
      method: API_PATCH({ colorMode: theme.palette.mode }),
    });
  }, [theme.palette.mode]);

  return (
    <Box
      className="sticky md:absolute top-0 md:right-4 flex flex-row items-center backdrop-blur-md z-50 px-3 rounded-xl gap-6 shadow-lg"
      display="flex"
      justifyContent="space-between"
      sx={{
        minHeight: "50px",
        backgroundColor: colors.glass,
      }}
    >
      <Box className="flex gap-2 w-full">
        <ExoSmartSearch />
        <Box className="hidden sm:block">
          <TimeBadge />
        </Box>
      </Box>

      <Box className="flex items-center py-1">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton>
        <Notifications />
        {hasPermissions("settings") && (
          <Item to="/settings" icon={<SettingsOutlinedIcon />} />
        )}

        <NavLink to="/profile" className="block sm:hidden ml-4 h-12">
          <ExoAvatar
            size="48px"
            id="nav-profile-picture"
            className="rounded-full mb-1 mx-auto"
            picture={profilePicture}
            name={firstName + " " + lastName}
            color={color}
          />
        </NavLink>
      </Box>
    </Box>
  );
};

export default Topbar;
