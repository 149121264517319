import { useTranslation } from "react-i18next";
import Header from "../../components/special/Header";
import ExoTable from "../../components/exo/ExoTable";
import useFetch from "../../api/useFetch";
import ExoTableRecipient from "../../components/exo/ExoTableRecipient";
import { Box } from "@mui/material";
import { LetterStatus } from "../../components/special/Status";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import { useState } from "react";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ExoDialog from "../../components/exo/ExoDialog";
import { NewLetter } from "../../components/new/NewLetter";
import ExtendLetter from "./ExtendLetter";

export const Letters = () => {
  const { t } = useTranslation();

  const { apiData, setApiData } = useFetch("letters");
  const [openNewLetter, setOpenNewLetter] = useState(false);
  const [edit, setEdit] = useState(null);

  const columns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <LetterStatus number={row.status}></LetterStatus>, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Subject"),
      key: "subject",
      width: "100%",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      hiddenMobile: true,
    },
    {
      header: t("Recipient"),
      key: "recipient",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <ExoTableRecipient row={row} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Attachments"),
      key: "attachmentCount",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <Box className="monospace">{row.attachmentCount}</Box>,
    },
  ];

  return (
    <>
      <Header title={t("Letters")} />

      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Letter")}
          icon={<NoteAddIcon />}
          onClick={() => setOpenNewLetter(true)}
        />
      </ExoTableToolbar>

      <ExoTable
        data={apiData}
        isLoading={!Boolean(apiData)}
        columns={columns}
        extendElement={({ row, onUpdate, onDelete }) => (
          <ExtendLetter
            row={row}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onEdit={setEdit}
          />
        )}
      />

      <ExoDialog open={openNewLetter || Boolean(edit)}>
        <NewLetter
          letters={apiData}
          setLetters={setApiData}
          startData={edit}
          onClose={() => {
            setOpenNewLetter(false);
            setEdit(null);
          }}
        />
      </ExoDialog>
    </>
  );
};
