import { WPEPreview } from "./WPEPreview";
import Typography from "@mui/material/Typography";
import { wpeList } from "./wpeList";
import { API_DELETE, DBRequest, useConfig } from "../../../../api/api";

export const WPE = ({ element, mode, editmode, onDelete }) => {
  const config = useConfig();

  const seObject = wpeList.find((obj) => obj.type === element.type);

  function handleDelete(elementToDel) {
    DBRequest({
      config,
      path: `work-packages/elements/${elementToDel.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(elementToDel),
    });
  }

  if (!seObject)
    return <Typography color="error">Cant find Element</Typography>;

  const Element = seObject.element;
  const Icon = seObject.Icon;

  if (mode === "preview")
    return (
      <WPEPreview
        element={element}
        editmode={editmode}
        Icon={Icon}
        onDelete={handleDelete}
      />
    );

  return (
    <Element
      element={element}
      editmode={editmode}
      Icon={Icon}
      onDelete={handleDelete}
    />
  );
};
