import { Box } from "@mui/material";

import { useTheme } from "@emotion/react";
import { tokens } from "../../global/theme/tokens";
import ExoWorkday from "../exo/ExoWorkday";
import { API_POST, DBRequest, useConfig } from "../../api/api";
import ExoForm from "../exo/ExoForm";
import { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

const NewTimeCorrection = ({
  onCreate,
  onCancle,
  onFeedback = () => {},
  userId,
  date,
  autoAccept,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState(
    date ? moment(date).format("YYYY-MM-DD") : null
  );

  useEffect(() => {
    if (date) setStartDate(moment(date).format("YYYY-MM-DD"));
  }, [date]);

  function handleSubmit(times) {
    DBRequest({
      config,
      path: "employeerequests",
      method: API_POST({
        userId,
        corrections: times,
        type: "timeCorrection",
        comment: "-",
        autoAccept: Boolean(autoAccept),
      }),
      onResponse: handleCreation,
      onFeedback,
    });
  }

  function handleCreation(data) {
    onCreate(data.data);
    onCancle();
  }

  function handleRangeChange(data) {
    setStartDate(data.startDate);
  }

  return (
    <Box className="z-10 w-full p-4 h-full flex flex-col overflow-hidden gap-2">
      <ExoForm
        fields={[{ type: "date", key: "startDate", label: t("Date") }]}
        onChange={handleRangeChange}
        startDataCollection={{
          startDate: date
            ? moment(date).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        }}
        noControls
      />
      <ExoWorkday
        labelFormat="dddd"
        onCancle={onCancle}
        format="DD.MM.YYYY"
        onSubmit={handleSubmit}
        userId={userId}
        dateStart={startDate}
        edit
        hourStart={8}
        hourEnd={22}
      />
    </Box>
  );
};

export default NewTimeCorrection;
