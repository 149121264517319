import { useTheme, Box, InputBase } from "@mui/material";
import { useEffect, useState } from "react";
import { SearchRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

const DBSearch = ({ param, onSearch, noInitialLoad, sort = "id" }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const cookies = new Cookies();
  const prefix = cookies.get("searchPrefix");

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!noInitialLoad) handleSearch({ target: { value: "" } });
  }, [config]);

  function handleSearch(event) {
    if (!config) return;

    const searchData = { q: event.target.value || " ", sort: [`${sort}:desc`] };
    SearchRequest({
      baseUrl: config.searchUrl,
      path: `indexes/${prefix}${param}/search`,
      method: "post",
      data: searchData,
      onResponse: (res) => onSearch(res.hits),
    });

    setSearch(event.target.value);
  }

  return (
    <Box
      className="flex backdrop-blur-lg py-1"
      borderRadius="3px"
      backgroundColor={colors.bgInput}
    >
      <InputBase
        sx={{ ml: 2, flex: 1 }}
        placeholder={t("Search")}
        value={search}
        onChange={handleSearch}
        className="w-full"
      />
    </Box>
  );
};

export default DBSearch;
