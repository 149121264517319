import { Box } from "@mui/material";
import { useConfig } from "../../api/api";
import { numberToString } from "../special/numberConverter";

const MoneyLabel = ({ money, symbolKey = "currencySymbol" }) => {
  const config = useConfig();
  return (
    <Box className=" whitespace-nowrap">{`${numberToString(money)} ${
      config !== null ? config.currency[symbolKey] : ""
    }`}</Box>
  );
};

export default MoneyLabel;
