import { useTheme, Box } from "@mui/material";

import { tokens } from "../../../global/theme/tokens";

const TaxAccount = ({ account, color, currentId, onChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function handleSelect() {
    onChange(account);
  }

  return (
    <Box
      onClick={handleSelect}
      className="w-full 
         flex flex-row hover:cursor-pointer gap-1"
      sx={{
        minHeight: "40px",
        backgroundColor:
          currentId === account.id ? colors.primary[400] : colors.glass,
      }}
    >
      <Box
        className="w-2"
        sx={{
          backgroundColor: color,
        }}
      ></Box>
      <Box className="w-52 monospace flex justify-start items-center">
        {account.account}
      </Box>
      <Box className="w-full flex justify-start items-center overflow-y-auto">
        {account.label}
      </Box>
    </Box>
  );
};

export default TaxAccount;
