import moment from "moment";
import { useEffect, useState } from "react";
import { useConfig } from "../../api/api";
import { logDOM } from "@testing-library/react";

const DateTimeLabel = ({ dateTime }) => {
  const config = useConfig();
  const [dateTimeLabel, setDateTimeLabel] = useState("");
  useEffect(() => {
    if (!config) return;
    const formattedDate = moment(dateTime).format(
      config.general.datetimeOutputFormat
    );

    setDateTimeLabel(formattedDate);
  }, [config]);

  return <>{dateTimeLabel}</>;
};

export default DateTimeLabel;
