import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { useState } from "react";
import { PositionNumber } from "./collapse-modules/PositionNumber";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../../api/api";
import CalculateIcon from "@mui/icons-material/Calculate";
import { numberToString } from "../../special/numberConverter";
import CloseIcon from "@mui/icons-material/Close";

export const PositionDiscount = ({ onCreate, open, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const [data, setData] = useState({});
  const [newDiscount, setNewDiscount] = useState(null);

  function handleChange(e, key) {
    if (e) setData({ ...data, [key]: e });
    calcNewPrice({ ...data, [key]: e });
  }

  function calcNewPrice(newData) {
    if (newData.discountBase && newData.discountRate) {
      setNewDiscount(newData.discountBase * (newData.discountRate / 100));
    } else {
      setNewDiscount(0);
    }
  }

  function handleSubmit() {
    onCreate({
      discount: newDiscount,
      discountBase: data.discountBase,
      discountRate: data.discountRate,
      name: t("Special discount"),
    });
    onClose();
  }

  return (
    <Box
      className="w-full fixed bottom-0 left-0 p-4 pb-16 rounded-t-lg backdrop-blur-lg transl"
      sx={{
        bgcolor: colors.bgInput,
        transform: `translate(0px, ${open ? "0%" : "100%"})`,
        transition: "0.4s ease",
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute" }}
        className="top-2 right-2"
      >
        <CloseIcon />
      </IconButton>
      <Typography className="pt-3">{t("Calculation discount")}</Typography>
      <Box className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-3">
        <PositionNumber
          data={data.discountBase || 0}
          label={"Discount base"}
          onFocus={() => {}}
          onChange={(e) => handleChange(e, "discountBase")}
          unit={config ? config.currency.currencySymbol : ""}
        />
        <PositionNumber
          data={data.discountRate || 0}
          label={"Discount Rate Position"}
          onFocus={() => {}}
          onChange={(e) => handleChange(e, "discountRate")}
          unit={"%"}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          className="flex gap-4"
        >
          <CalculateIcon />
          {` ${
            newDiscount ? numberToString(newDiscount, 2) : numberToString(0, 2)
          } ${config ? config.currency.currencySymbol : ""} ${t(
            "Create Discount Position"
          )}`}
        </Button>
      </Box>
    </Box>
  );
};
