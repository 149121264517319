import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { Box, Button, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import ExoTable from "../exo/ExoTable";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../label/MoneyLabel";
import ExoTableRecipient from "../exo/ExoTableRecipient";

const SelectOrder = ({
  apiUrl,
  onSelect,
  filter = "",
  selected,
  button,
  totalAmount,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  // request
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: apiUrl || `orders?${filter}`,
      method: API_GET,
      onResponse: setData,
      onLoading: setIsLoading,
      debug: true,
    });
  }, [config]);

  //tabel
  const Columns = [
    {
      header: t("Ordner Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ), // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Title"), // header of column
      key: "title", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "100%", // width of column
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Recipient"),
      key: "recipient",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "270px",
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      cell: ({ row }) => <ExoTableRecipient row={row} />, // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
    {
      header: t("Open"), // header of column
      key: "openVolume", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "200px", // width of column
      cell: ({ row }) => (
        <MoneyLabel money={totalAmount ? row.volume : row.openVolume} />
      ), // custom xml for cells in the column. you get the row as object
      onClick: (row) => onSelect(row),
    },
  ];

  return (
    <Box className="h-full">
      <ExoTable
        isSelectedElement={(row) => selected && row.id === selected.id}
        data={data}
        isLoading={isLoading}
        columns={Columns}
        noBackground
      />
    </Box>
  );
};

export default SelectOrder;
