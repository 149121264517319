import { useTheme, Box, Avatar, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../components/exo/ExoTableToolbar";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ExoTable from "../../components/exo/ExoTable";
import { OrderStatus } from "../../components/special/Status";
import NewOrder from "../../components/new/NewOrder";
import { useParams } from "react-router-dom";
import Header from "../../components/special/Header";
import ExoTableRecipient from "../../components/exo/ExoTableRecipient";
import { useTranslation } from "react-i18next";
import useFetch from "../../api/useFetch";
import ExtendDeliveryNote from "./extendDeliveryNote";
import NewDeliveryNote from "../../components/new/NewDeliveryNote";
import { numberToString } from "../../components/special/numberConverter";

const DeliveryNotes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const { apiData, isLoading, setApiData } = useFetch("delivery-notes");

  // table
  const Columns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => (
        <Box className="monospace whitespace-nowrap">{row.nr}</Box>
      ),
    },
    {
      header: t("Title"),
      key: "title",
      width: "100%",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
    },
    {
      header: t("Recipient"),
      key: "recipient",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "270px",
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      cell: ({ row }) => <ExoTableRecipient row={row} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Items"),
      key: "itemCount",
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "100px",
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      cell: ({ row }) => <Box>{numberToString(row.itemCount, 2)}</Box>, // custom xml for cells in the column. you get the row as object
    },
  ];

  // actions
  const [openNewOrder, setOpenNewOrder] = useState(false);
  function handleNewOrder() {
    setOpenNewOrder(true);
  }
  return (
    <>
      <Header title={t("Delivery Notes")} />
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("New Delivery Note")}
          icon={<NoteAddIcon />}
          onClick={handleNewOrder}
        />
      </ExoTableToolbar>

      <ExoTable
        data={apiData}
        isLoading={!Boolean(apiData)}
        columns={Columns}
        extendElement={({ row, onUpdate }) => (
          <ExtendDeliveryNote row={row} onUpdate={onUpdate} />
        )}
      />

      <NewDeliveryNote
        open={openNewOrder}
        onClose={() => setOpenNewOrder(false)}
        deliveryNotes={apiData}
        setDeliveryNotes={setApiData}
      />
    </>
  );
};

export default DeliveryNotes;
