import { useTheme, Dialog, Box } from "@mui/material";
import { useState } from "react";
import Feedback from "../special/Feedback";
import { API_POST, API_PATCH, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import * as Yup from "yup";
import ExoForm from "../exo/ExoForm";
import { useTranslation } from "react-i18next";
import Loader from "../special/Loader";

const NewProduct = ({ onClose, onCreate = () => {}, startData, fullWidth }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  // form
  const fields = [
    {
      label: `${t("Picture")} (${t("optional")})`,
      type: "image",
      key: "image",
    },
    {
      label: t("Name"),
      type: "text",
      key: "name",
    },
    {
      label: `${t("Description")} (${t("optional")})`,
      type: "description",
      key: "description",
    },
    {
      label: `${t("Categories")} (${t("optional")})`,
      type: "productCategoryIds",
      key: "productCategoryIds",
    },
    {
      label: `${t("Unit")} (${t("optional")})`,
      type: "text",
      key: "unit",
    },
    {
      label: `${t("Purchase Price")} (${t("optional")})`,
      type: "money",
      key: "purchasePrice",
      unit: "€",
    },
    {
      label: t("Tax Rate"),
      type: "text",
      key: "taxRate",
      unit: "%",
    },
    {
      label: t("Selling Price"),
      type: "money",
      key: "price",
      unit: "€",
    },
  ];
  const config = useConfig();
  const [isLoading, setIsLoading] = useState(false);
  function handleSubmit(product) {
    DBRequest({
      config,
      path: product.id ? `products/${product.id}` : "products",
      method: product.id ? API_PATCH(product) : API_POST(product),
      onResponse: handleData,
      onFeedback: handleFeedback,
      onLoading: setIsLoading,
    });
  }
  function handleData(data) {
    onCreate(data.data);
    onClose();
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return (
    <Box
      className="p-2 h-full overflow-y-auto"
      sx={{ width: fullWidth ? "100%" : "700px", maxWidth: "100%" }}
    >
      <Loader active={isLoading} />
      <ExoForm
        startDataCollection={startData}
        fields={fields}
        onCancle={onClose}
        header={t("New Product")}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        noDifferences={Boolean(startData && !startData.id)}
      />
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

// validation
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  price: Yup.number()
    .typeError("Price must be a number")
    .required("Price is required"),
  taxRate: Yup.number()
    .typeError("Tax rate must be a number")
    .required("Tax rate is required"),
});

export default NewProduct;
