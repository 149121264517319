import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../global/theme/tokens";

const tabsDemo = [
  {
    label: "DEMO Tab1",
    content: <Box>TEST1</Box>,
  },
  {
    label: "DEMO Tab2",
    content: <Box>TEST2</Box>,
  },
];

const ExoTabs = ({
  tabs = tabsDemo,
  orientation,
  noBackground = false,
  allowOverflow = false,
  onChange = () => {},
  className = "w-full h-full flex flex-col backdrop-blur-lg rounded-lg overflow-hidden",
  sx = {},
  verticalBreakWidth = 800,
  noPrerender,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [value, setValue] = useState(null);

  useEffect(() => {
    if (value !== null) return;
    var hasTab = false;

    tabs.forEach((tab, index) => {
      if (!hasTab && !tab.hidden) {
        hasTab = true;
        setValue(index);
      }
    });
  }, [tabs]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  // handle orientation
  const exoTabRef = useRef(null);
  const [currentOrientation, setCurrentOrientation] = useState("horizontal");
  useEffect(() => {
    if (!exoTabRef) return;

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial setup
    handleResize();

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [exoTabRef]);

  function handleResize() {
    if (exoTabRef.current) {
      const currentWidth = exoTabRef.current.offsetWidth;
      setCurrentOrientation(
        currentWidth < verticalBreakWidth
          ? "horizontal"
          : orientation || "horizontal"
      );
    }
  }

  return (
    <Box
      ref={exoTabRef}
      className={className}
      sx={{
        ...sx,
        height: allowOverflow ? "unset" : "100%",
        flexDirection: currentOrientation === "horizontal" ? "column" : "row",
        backgroundColor: noBackground ? "transparent" : colors.glass,
      }}
    >
      <Tabs
        value={value || 0}
        onChange={handleChange}
        orientation={currentOrientation}
        sx={{
          overflow: "visible",

          height: currentOrientation === "horizontal" ? "auto" : "100%",
          minHeight: "fit-content",
          boxShadow:
            currentOrientation === "horizontal"
              ? `0px 4px 5px rgba(0,0,0,0.1)`
              : `4px 0px 5px rgba(0,0,0,0.1)`,
          ".MuiTabs-indicator": {
            display: "none",
          },
          ".MuiTabs-flexContainer": {
            flexWrap: currentOrientation === "horizontal" ? "wrap" : "none",
            overflowY: currentOrientation === "horizontal" ? "hidden" : "auto",
          },
          div: {
            height: "100%",
            overflowY: "hidden",
          },
          "& button": {
            borderRadius: "0.5rem",
            alignItems:
              currentOrientation === "horizontal" ? "center" : "start",
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            sx={{
              display: tab.hidden ? "none" : "flex",
            }}
            key={tab.label + index}
            label={tab.label}
            icon={tab.icon}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
      <Box
        className="p-2 w-full flex relative  overflow-y-auto h-auto lg:h-full"
        sx={{ height: allowOverflow ? "unset" : "100%" }}
      >
        {tabs.map((tab, index) => (
          <ExoTabPanel
            noPrerender={noPrerender}
            key={tab.label + "-tabPanel"}
            active={!tab.hidden && value === index}
            tab={tab}
          />
        ))}
      </Box>
    </Box>
  );
};

export const ExoTabPanel = ({ active, tab, noPrerender }) => {
  if ((noPrerender && !active) || tab.hidden) return;
  return (
    <Box className={(!active && "hidden") + " h-full flex relative w-full"}>
      {tab.content}
    </Box>
  );
};

function a11yProps(index) {
  return {
    id: `exo-tab-${index}`,
    "aria-controls": `exo-tabpanel-${index}`,
  };
}

export default ExoTabs;
