import { Box, IconButton, RadioGroup, Typography } from "@mui/material";
import OrderItem from "./OrderItem";
import { createElement, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const OrderGroup = ({ group, onChange, groupKey, activeOrder }) => {
  function handleParamChange(orderParam) {
    onChange(orderParam, Boolean(activeOrder.orderDirection));
  }
  function handleDirectionChange() {
    onChange(activeOrder.orderParam, !Boolean(activeOrder.orderDirection));
  }

  return (
    <Box className="flex flex-col">
      <Box className="flex flex-row justify-between items-center">
        <Typography variant="h6" className="uppercase">
          {group.label}
        </Typography>
        <IconButton
          onClick={handleDirectionChange}
          sx={{
            transform: activeOrder.orderDirection
              ? "rotate(0deg)"
              : "rotate(180deg)",
            transition: "0.3s",
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
      <RadioGroup>
        {group.items.map((order, index) => {
          return createElement(OrderItem, {
            item: order,
            itemIndex: index,
            groupKey: groupKey,
            onChange: () => handleParamChange(order.value),
            key: index,
            active: Boolean(order.value === activeOrder.orderParam),
          });
        })}
      </RadioGroup>
    </Box>
  );
};

export default OrderGroup;
