import {
  useTheme,
  Box,
  Typography,
  Button,
  Backdrop,
  Collapse,
} from "@mui/material";
import { tokens } from "./theme/tokens";
import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import menuItemList from "./menuItemList";

const MobileMenu = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Box
        className="sm:hidden relative z-50  overflow-visible w-full"
        sx={{
          position: "absolute",
          transition: "transform 0.3s",
        }}
      >
        <Button
          onClick={() => setOpen(!open)}
          sx={{
            position: "absolute",
            borderRadius: "10px 10px 0 0",
            top: "-35px",
            height: "35px",
            backgroundColor: colors.glass,
            ":hover": {
              backgroundColor: colors.card,
            },
          }}
          className="z-50 w-full backdrop-blur-lg"
          variant="contained"
        >
          <ArrowDropUpIcon
            className={open ? "rotate-180" : ""}
            sx={{
              transition: "transform 0.3s",
            }}
          />
        </Button>
        <Collapse in={open}>
          <Box
            className="w-full flex flex-row gap-2 z-40 overflow-x-auto pt-2 relative border-t-2"
            sx={{
              borderColor: colors.blueAccent[500],
            }}
          >
            <Backdrop open={open} onClick={handleClose}></Backdrop>
            {menuItemList().map((group) => (
              <MenuGroup
                key={group.title}
                group={group}
                onClose={handleClose}
              />
            ))}
          </Box>
        </Collapse>
      </Box>
    </>
  );
};

const MenuGroup = ({ group, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const hasHiddenFalse = group.items.some((item) => item.hidden === false);

  if (!hasHiddenFalse) return;

  return (
    <Box
      className="relative flex flex-col backdrop-blur-lg rounded-lg"
      sx={{ backgroundColor: colors.glass }}
    >
      <Typography
        className="py-2 border-b-2"
        textAlign="center"
        variant="h6"
        color={colors.grey[800]}
        borderColor={colors.blueAccent[500]}
      >
        {group.title}
      </Typography>
      <Box className="flex flex-col gap-2 pt-2">
        {group.items.map((item) => (
          <MenuItem
            key={group.title + "-" + item.title}
            onSelect={onClose}
            title={item.title}
            to={item.to}
            icon={item.icon}
            hidden={item.hidden}
          />
        ))}
      </Box>
    </Box>
  );
};

const MenuItem = ({ title, to, icon, onSelect, hidden }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  if (hidden) return;

  return (
    <NavLink to={to} activeclassname="active" onClick={onSelect}>
      <Box
        className="flex flex-row justify-center w-36 items-center gap-2 px-3 py-0.5 "
        sx={{
          "& svg": {
            color: colors.grey[900],
          },
          "& p": {
            color: colors.grey[900],
          },
          ".active & ": {
            "& svg": {
              color: colors.grey[600],
            },
            "& p": {
              color: colors.grey[600],
            },
          },
        }}
      >
        <Box
          className="flex flex-col rounded-md items-center gap-2 px-1.5 py-1.5 nav-item w-full"
          sx={{
            ".active & ": {
              backgroundColor: colors.card,
            },
          }}
        >
          {icon}
          <Typography className="nav-text">{title}</Typography>
        </Box>
      </Box>
    </NavLink>
  );
};

export default MobileMenu;
