import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import ExoTable from "../exo/ExoTable";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../label/MoneyLabel";
import useFetch from "../../api/useFetch";
import ExoTableRecipient from "../exo/ExoTableRecipient";

const SelectInvoice = ({
  apiUrl,
  onSelect,
  filter = "",
  selected = {},
  button,
  buttonIcon,
  buttonLabel,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const { apiData, isLoading } = useFetch(apiUrl || `invoices?${filter}`);

  //tabel
  const Columns = [
    {
      header: t("Nr"), // header of column
      key: "nr", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "100px", // width of column
      cell: ({ row }) => <Box className="monospace">{row.nr}</Box>, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Title"), // header of column
      key: "title", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "auto", // width of column
    },
    {
      header: t("Recipient"), // header of column
      key: "recipient", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "auto", // width of column
      cell: ({ row }) => <ExoTableRecipient row={row} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Volume"), // header of column
      key: "amount", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "200px", // width of column
      cell: ({ row }) => <MoneyLabel money={row.amount} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: "", // header of column
      key: "amount", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "100px", // width of column
      cell: ({ row }) => (
        <Button
          onClick={() => onSelect(row)}
          className="flex flex-row gap-2"
          variant="contained"
          sx={
            selected.id == row.id && {
              backgroundColor: colors.selected,
            }
          }
        >
          {buttonIcon}
          {buttonLabel || t("Select")}
        </Button>
      ), // custom xml for cells in the column. you get the row as object
    },
  ];

  return (
    <ExoTable
      className="h-full w-full"
      data={apiData}
      isLoading={isLoading}
      columns={Columns}
    />
  );
};

export default SelectInvoice;
