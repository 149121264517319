import { Box, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SelectProduct from "../select/SelectProduct";
import SelectService from "../select/SelectService";
import Sum from "./position-modules/Sum";
import Controls from "./position-modules/Controls";
import { numberToString } from "../special/numberConverter";
import Trans from "../special/Trans";
import { useTranslation } from "react-i18next";
import ExoDialog from "./ExoDialog";
import { PositionsHeader } from "./positions/PositionsHeader";
import { PositionsRowEmpty } from "./positions/PositionsRowEmpty";
import { PositionsRow } from "./positions/PositionsRow";
import { PositionImage } from "./positions/collapse-modules/PositionImage";
import { PositionNumber } from "./positions/collapse-modules/PositionNumber";
import { PositionPreCalculation } from "./positions/collapse-modules/PositionPreCalculation";
import { PositionText } from "./positions/collapse-modules/PositionText";
import { PositionDescription } from "./positions/collapse-modules/PositionDescription";
import { PositionsGroup } from "./positions/PositionsGroup";
import { useDebounce } from "use-debounce";
import { PositionDiscount } from "./positions/PositionDiscount";

const ExoPositions = forwardRef(
  (
    {
      className,
      onPositionChange,
      startPositions,
      onFocus = () => {},
      descriptionVars = [],
      showVars,
      hideServices,
      hideProducts,
      hideNewPosition,
      hideSum,
      hideGroups,
      hideDiscount,
      emptyStart,
      imageType,
      imageApiFilter,
      defaultTaxRate,
      openAmount,
      groupKey = "name",
      collapseModules = [
        { component: PositionImage, key: "image", label: "Image" },
        {
          component: PositionDescription,
          key: "description",
          label: "Description",
        },
        {
          component: PositionNumber,
          key: "taxRate",
          label: "Tax Rate",
          unit: "%",
          decimalPlaces: 0,
        },
        {
          component: PositionText,
          key: "unit",
          label: "Unit",
        },
        {
          component: PositionPreCalculation,
          key: "price",
          label: "Calculation of sales price",
        },
      ],
      columns = [
        {
          text: Trans("Position"),
          width: "80px",
          align: "left",
          key: "position",
          className: "monospace justify-center",
          number: true,
          decimalPlaces: 2,
          editable: false,
          hidden: false,
          default: 1,
        },
        {
          text: Trans("Description"),
          width: "auto",
          align: "left",
          key: "name",
          editable: true,
          hidden: false,
          default: "",
        },
        {
          text: Trans("Amount"),
          width: "80px",
          align: "center",
          key: "amount",
          className: "monospace",
          number: true,
          decimalPlaces: 2,
          editable: true,
          hidden: false,
          default: 0,
        },
        {
          text: Trans("Unit Price"),
          width: "120px",
          align: "right",
          key: "price",
          className: "monospace",
          unit: " €",
          number: true,
          editable: true,
          hidden: false,
          default: 0,
        },
        {
          text: Trans("Sum"),
          width: "120px",
          align: "right",
          key: "custom",
          unit: " €",
          className: "justify-end",
          hideOnMobile: true,
          hidden: false,
          custom: (element, unit) => {
            var sum = 0;
            if (element.amount && element.price) {
              sum = (
                parseFloat(element.amount) * parseFloat(element.price)
              ).toFixed(2);
            }
            return (
              <Box className="w-full text-right monospace">
                {element.isOptional && "("}
                {numberToString(sum)}
                {unit}
                {element.isOptional && ")"}
              </Box>
            );
          },
        },
        {
          key: "status",
          hidden: true,
          default: 0,
        },
        {
          key: "description",
          hidden: true,
          default: "",
        },
        {
          key: "positionableId",
          hidden: true,
          default: null,
        },
        {
          key: "positionableType",
          hidden: true,
          default: "custom",
        },
      ],
    },
    ref
  ) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

    const { t } = useTranslation();

    const [items, setItems] = useState([]);
    const [defaultPosition, setDefaultPosition] = useState(null);

    const [debouncedItems] = useDebounce(items, 700);

    const [sum, setSum] = useState({
      netto: 0,
      tax: 0,
    });

    useEffect(() => {
      if (startPositions && startPositions.length > 0) setItems(startPositions);
    }, [startPositions]);

    useEffect(() => {
      var newDefaultPositions = {};
      columns.forEach((column) => {
        newDefaultPositions[column.key] = column.default;
      });
      newDefaultPositions.taxRate =
        defaultTaxRate === undefined || defaultTaxRate === null
          ? 19
          : defaultTaxRate;
      setDefaultPosition(newDefaultPositions);
    }, []);

    useEffect(() => {
      recalculateSum(items);
    }, [debouncedItems]);

    useEffect(() => {
      if (emptyStart) return;
      if (items.length === 0 && defaultPosition) {
        handleNewPosition(defaultPosition);
      }
    }, [defaultPosition]);

    function recalculateSum(newItems) {
      var newSum = 0;
      var newTax = 0;
      newItems.forEach((element) => {
        if (element.isGroup || element.isOptional) return;
        newSum += element.amount * element.price;
        newTax += element.amount * element.price * (element.taxRate / 100);
      });
      setSum({
        netto: newSum,
        tax: newTax,
      });
    }

    // drag stuff
    const [isDragging, setIsDragging] = useState(false);

    const handleOnDragStart = (result) => {
      setIsDragging(true);
    };

    const handleOnDragEnd = (result) => {
      setIsDragging(false);
      const { source, destination } = result;
      // If item was dropped outside of a droppable, do nothing
      if (!destination) {
        return;
      }
      // If item was dropped in the "trash" droppable, delete the item
      var newItems = [...items];
      if (destination.droppableId === "trash") {
        newItems.splice(source.index, 1);
      } else {
        // Otherwise, reorder the items as usual
        const [removed] = newItems.splice(source.index, 1);
        newItems.splice(destination.index, 0, removed);
      }
      const orderedPositions = calculateNewPositions(newItems);
      setItems(orderedPositions);
      onPositionChange(orderedPositions);
    };

    function handleNewPosition(position) {
      var newItems = [...items, { ...position }];

      const newOrderedPositions = calculateNewPositions(newItems);

      onPositionChange(newOrderedPositions);
      setItems(newOrderedPositions);
    }

    function handleNewGroup() {
      var newGroup = {
        [groupKey]: "",
        isGroup: true,
        amount: 0,
        unit: "",
        price: 0,
        taxRate: 0,
        positionableId: null,
        positionableType: "custom",
        taskTitle: "",
        pricePerHour: "",
        monthlyLimit: 0,
        status: 0,
      };
      handleNewPosition(newGroup);
    }

    function calculateNewPositions(positions) {
      var newPositions = positions;

      var groupCount = 0;
      var posCount = 1;

      newPositions.forEach((pos) => {
        if (pos.isGroup) {
          groupCount = groupCount ? groupCount + 1 : posCount;
          posCount = 1;
          return (pos.position = `${groupCount}`);
        }

        pos.position = groupCount ? `${groupCount}.${posCount}` : `${posCount}`;

        posCount++;
      });

      return newPositions;
    }

    // product
    const [openProducts, setOpenProducts] = useState(false);
    function handleProductSelect(product) {
      const productEntry = {
        productNr: product.nr,
        status: 0,
        position: 1,
        name: product.name,
        image: product.image,
        description: product.description,
        amount: 0,
        unit: product.unit,
        price: product.price,
        taxRate: product.taxRate,
        positionableId: product.id,
        positionableType: "product",
        taskTitle: product.name,
        pricePerHour: product.price,
        monthlyLimit: 0,
      };
      handleNewPosition(productEntry);
    }

    // service
    const [openServices, setOpenServices] = useState(false);
    function handleServiceSelect(service) {
      const serviceEntry = {
        serviceNr: service.nr,
        status: 0,
        position: 1,
        name: service.name,
        image: service.image,
        description: service.description,
        amount: 0,
        unit: service.unit,
        price: service.price,
        taxRate: service.taxRate,
        positionableId: service.id,
        positionableType: "service",
        taskTitle: service.name,
        pricePerHour: service.price,
        monthlyLimit: 0,
      };
      handleNewPosition(serviceEntry);
    }

    // exposed functions
    useImperativeHandle(ref, () => ({
      setItems: setItems,
      items: items,
    }));

    // discount
    const [openDiscount, setOpenDiscount] = useState(false);
    function handleNewDiscount(newDiscount) {
      handleNewPosition({
        ...defaultPosition,
        price: newDiscount.discount * -1,
        amount: 1,
        name: newDiscount.name,
      });
    }

    return (
      <>
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          onDragStart={handleOnDragStart}
        >
          <Box
            className={`${className} relative`}
            sx={{
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
              "& .MuiAccordionSummary-root": {
                minHeight: "26px",
              },
              "& th:first-child": {
                borderTopLeftRadius: "8px",
              },
              "& th:last-child": {
                borderTopRightRadius: "8px",
              },
              "& tr:last-child>td:first-child": {
                borderBottomLeftRadius: "8px",
              },
              "& tr:last-child>td:last-child": {
                borderBottomRightRadius: "8px",
              },
              "& td, th": {
                backgroundColor: colors.bgInput,
              },
              "& .position-group td": {
                backgroundColor: colors.selected,
              },
              "& .order-position td": {
                background: `linear-gradient(${colors.selected}40, ${colors.selected}40), linear-gradient(${colors.bgInput}, ${colors.bgInput})`,
              },
            }}
          >
            <Droppable droppableId="positions">
              {(provided) => (
                <table
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="w-full"
                >
                  <tbody>
                    <PositionsHeader
                      collapseModules={collapseModules}
                      columns={columns}
                    />

                    {items.length === 0 && <PositionsRowEmpty />}

                    {items.map((item, index) =>
                      item.isGroup ? (
                        <PositionsGroup
                          key={"drag-" + item.id + "-" + index}
                          index={index}
                          items={items}
                          item={item}
                          columns={columns}
                          groupKey={groupKey}
                          collapseModules={collapseModules}
                          onFocus={(ref, key) =>
                            onFocus({
                              index: index,
                              key,
                              ref: ref,
                            })
                          }
                          onChange={(newItems) => {
                            setItems(newItems);
                            onPositionChange(newItems);
                          }}
                        />
                      ) : (
                        <PositionsRow
                          key={"drag-" + item.id + "-" + index}
                          collapseModules={collapseModules}
                          index={index}
                          items={items}
                          item={item}
                          columns={columns}
                          imageType={imageType}
                          imageApiFilter={imageApiFilter}
                          descriptionVars={descriptionVars}
                          showVars={showVars}
                          openAmount={openAmount}
                          onFocus={(ref, key) =>
                            onFocus({
                              index: index,
                              key,
                              ref: ref,
                            })
                          }
                          onChange={(newItems) => {
                            setItems(newItems);
                            onPositionChange(newItems);
                          }}
                        />
                      )
                    )}
                    {provided.placeholder}
                    <Controls
                      hideGroups={Boolean(hideGroups)}
                      hideSum={Boolean(hideSum)}
                      hideServices={Boolean(hideServices)}
                      hideProducts={Boolean(hideProducts)}
                      hideNewPosition={Boolean(hideNewPosition)}
                      hideDiscount={Boolean(hideDiscount)}
                      cols={columns}
                      onNewGroup={handleNewGroup}
                      onNewPosition={() => handleNewPosition(defaultPosition)}
                      onSelectProduct={() => setOpenProducts(true)}
                      onSelectService={() => setOpenServices(true)}
                      onNewDiscount={() => setOpenDiscount(!openDiscount)}
                    />
                    {!hideSum && (
                      <Sum
                        sum={sum}
                        cols={columns}
                        collapseModules={collapseModules}
                      />
                    )}
                  </tbody>
                </table>
              )}
            </Droppable>
            <Droppable droppableId="trash">
              {(provided, snapshot) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex"
                  sx={{
                    width: "60px",
                    height: "60px",
                  }}
                >
                  <DeleteOutlineIcon
                    className="absolute bottom-0 left-0"
                    sx={{
                      fontSize: "60px",
                      opacity: isDragging ? "1" : "0",
                      transform: isDragging ? "scale(1)" : "scale(0)",
                      color: snapshot.isDraggingOver
                        ? colors.redAccent[500]
                        : colors.primary[500],
                      transition: "0.4s",
                    }}
                  />
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
        </DragDropContext>
        <ExoDialog open={openProducts} onClose={() => setOpenProducts(false)}>
          <SelectProduct
            onSelect={handleProductSelect}
            onClose={() => setOpenProducts(false)}
          />
        </ExoDialog>
        <ExoDialog open={openServices} onClose={() => setOpenServices(false)}>
          <SelectService
            onSelect={handleServiceSelect}
            onClose={() => setOpenServices(false)}
          />
        </ExoDialog>
        <PositionDiscount
          open={openDiscount}
          onClose={() => setOpenDiscount(false)}
          onCreate={handleNewDiscount}
        />
      </>
    );
  }
);

export default ExoPositions;
