import { useTheme } from "@emotion/react";
import { Box, Button, Skeleton, colors } from "@mui/material";
import Typography from "@mui/material/Typography";
import { tokens } from "../../global/theme/tokens";
import { generateMediaQuerysForDashboardGridElement } from "./dashboardGridManagement";
import { useTranslation } from "react-i18next";
import { periodStepMap } from "./modulePeriodStep";
import { useState } from "react";

const ModuleLayout = ({
  label,
  children,
  colSpan = 1,
  rowSpan = 1,
  minHeight = "auto",
  isLoading,
  onPeriodChange,
  periods = [],
  startPeriod,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const mediaQuerys = generateMediaQuerysForDashboardGridElement(
    colSpan,
    rowSpan
  );
  return (
    <Box
      className="backdrop-blur-lg rounded-lg flex h-full flex-col overflow-hidden relative"
      sx={{
        minHeight: minHeight,
        backgroundColor: colors.glass,
        ...mediaQuerys,
      }}
    >
      {isLoading ? (
        <Skeleton
          className="w-full h-full"
          sx={{ transform: "none", height: "100%" }}
        />
      ) : (
        <>
          <PeriodController
            periods={periods}
            onChange={onPeriodChange}
            startPeriod={startPeriod}
          />
          {label && (
            <Typography variant="h4" textAlign="center" className="pt-2">
              {label}
            </Typography>
          )}
          <Box className="w-full h-full flex flex-col overflow-hidden p-2">
            {children}
          </Box>
        </>
      )}
    </Box>
  );
};

const PeriodController = ({ onChange, periods, className, startPeriod }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(
    startPeriod || "quarter"
  );

  function handleChange(newPeriod) {
    setSelectedPeriod(newPeriod);
    onChange(newPeriod);
  }

  return (
    <Box className={"flex gap-1 absolute right-0 bottom-0 z-30 " + className}>
      {periods.map((period) => (
        <PeriodButton
          period={period}
          onClick={handleChange}
          selected={selectedPeriod === period}
        />
      ))}
    </Box>
  );
};

const PeriodButton = ({ selected, period, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  return (
    <Button
      sx={{
        color: `${colors.grey[800]}${selected ? "AB" : 70}`,
        bgcolor: selected ? colors.card : "transparent",
        ":hover": {
          bgcolor: colors.card,
        },
      }}
      key={period}
      onClick={() => onClick(period)}
    >
      {t(periodStepMap[period].label)}
    </Button>
  );
};

export default ModuleLayout;
