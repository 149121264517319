import {
  useTheme,
  Box,
  Typography,
  Skeleton,
  Collapse,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useRef, useState } from "react";
import { tokens } from "../../global/theme/tokens";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { API_DELETE, API_POST, DBRequest, useConfig } from "../../api/api";
import Feedback from "./Feedback";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { useTranslation } from "react-i18next";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { ExoVariants } from "../exo/ExoVariants";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ExoDialog from "../exo/ExoDialog";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import MoneyLabel from "../label/MoneyLabel";
import { SelectReceipt } from "../select/SelectReceipt";
import SelectInvoice from "../select/SelectInvoice";
import PersonIcon from "@mui/icons-material/Person";
import { SelectPayroll } from "../select/SelectPayroll";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
const Transaction = ({
  data,
  isConnected,
  onClick = () => {},
  onChange = () => {},
  onDelete = () => {},
  onEdit = () => {},
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const [open, setOpen] = useState(false);
  function handleClick() {
    onClick(data);
    setOpen(!open);
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });

  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
    if (type === "success") formRef.current.reset();
  }

  // actions
  const [openForm, setOpenForm] = useState(false);
  function handleCreateRelation(relationData) {
    const relation = {
      relationType: relationData.type,
      relations: [relationData[relationData.type].id],
      mode: "add",
    };
    DBRequest({
      config,
      path: "transactions/" + data.id + "/relations",
      method: API_POST(relation),
      onResponse: onChange,
      onFeedback: handleFeedback,
    });
    setOpenForm(false);
  }

  function handleDelRelation(relationData, type) {
    const relation = {
      relationType: type,
      relations: [relationData.id],
      mode: "remove",
    };
    DBRequest({
      config,
      path: "transactions/" + data.id + "/relations",
      method: API_POST(relation),
      onResponse: onChange,
      onFeedback: handleFeedback,
    });
  }

  const variants = [
    {
      label: t("Invoice"),
      Icon: ReceiptIcon,
      content: (
        <NewInvoiceRelation
          onClose={() => setOpenForm(false)}
          formRef={formRef}
          onSubmit={handleCreateRelation}
        />
      ),
    },
    {
      label: t("Receipt"),
      Icon: RequestQuoteIcon,
      content: (
        <NewReceiptRelation
          onClose={() => setOpenForm(false)}
          formRef={formRef}
          onSubmit={handleCreateRelation}
        />
      ),
    },
    {
      label: t("Salary"),
      Icon: PersonIcon,
      content: (
        <NewPayrollRelation
          onClose={() => setOpenForm(false)}
          formRef={formRef}
          onSubmit={handleCreateRelation}
        />
      ),
    },
  ];

  const hasDocuments = Boolean(
    (data.invoices && data.invoices.length) ||
      (data.receipts && data.receipts.length) ||
      (data.payrolls && data.payrolls.length)
  );
  const hasOpenAmount = parseFloat(data.openAmount).toFixed(2) !== "0.00";

  return (
    <Box
      className={` rounded-lg backdrop-blur-lg transition-transform w-full overflow-hidden`}
      sx={{
        backgroundColor: colors.card,
        ":hover": {
          transform: "scale(1.01)",
        },
      }}
    >
      <Box
        className="py-1 px-3"
        sx={{
          borderLeft: hasOpenAmount
            ? `4px solid ${colors.redAccent[400]}`
            : `4px solid ${colors.grey[400]}`,
        }}
      >
        <Box
          className="flex flex-row gap-2 justify-between hover:cursor-pointer "
          onClick={handleClick}
        >
          <Box className="w-full">
            <Box className="flex flex-row justify-between items-start w-full gap-2">
              <Typography variant="p">
                <b>
                  {data.transactionPartnerName
                    ? data.transactionPartnerName
                    : data.bookingText}
                </b>
              </Typography>
              <Box>
                {hasDocuments && (
                  <Tooltip title="Accounted">
                    {hasOpenAmount ? (
                      <Typography>
                        <MoneyLabel money={data.openAmount} />
                      </Typography>
                    ) : (
                      <PriceCheckIcon />
                    )}
                  </Tooltip>
                )}
              </Box>
            </Box>
            <Typography variant="p" sx={{ color: colors.grey[600] }}>
              {t(data.bookingText)} | {data.intendedUse}
            </Typography>
          </Box>
          <Box className="flex justify-end items-center">
            <Typography
              noWrap
              variant="p"
              textAlign="right"
              sx={{
                minWidth: "100px",

                color:
                  data.amount >= 0
                    ? colors.money.positive
                    : colors.money.negative,
              }}
            >
              <strong>
                <MoneyLabel money={data.amount} symbolKey="currencyIsoCode" />
              </strong>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Collapse in={open}>
        {open && (
          <Box>
            <Box
              className=" w-full rounded-full mb-2"
              sx={{
                backgroundColor: colors.grey[300],
                height: "1px",
                width: "100%",
              }}
            ></Box>
            {!isConnected && (
              <TransactionToolbar
                onDelete={onDelete}
                onEdit={onEdit}
                transaction={data}
              />
            )}
            <Box className="flex flex-col md:flex-row gap-2 md:gap-0 justify-between">
              <Box className="flex flex-col gap-1 px-4">
                {TransactionInfoList.map((info, index) => (
                  <InfoText
                    label={info.label}
                    value={data[info.key]}
                    key={data.id + "-info-" + index}
                    hidden={Boolean(data[info.key] === "0")}
                  />
                ))}
              </Box>
              <Box className="flex flex-col gap-2 p-2">
                <Button
                  className="flex flex-row gap-1"
                  onClick={() => setOpenForm(true)}
                >
                  <NoteAddIcon />
                  {t("New Assignment")}
                </Button>
                {data.invoices &&
                  data.invoices.map((assignment, index) => (
                    <Assignment
                      type="invoice"
                      label={t("Invoice")}
                      title={assignment.title}
                      onDelete={handleDelRelation}
                      data={assignment}
                      key={"invoice-" + assignment.id}
                      amount={assignment.amount + assignment.taxAmount}
                    />
                  ))}
                {data.receipts &&
                  data.receipts.map((assignment, index) => (
                    <Assignment
                      type="receipt"
                      label={t("Receipt")}
                      title={assignment.title}
                      onDelete={handleDelRelation}
                      data={assignment}
                      key={"receipt-" + assignment.id}
                      amount={
                        assignment.amount +
                        assignment.amount * (assignment.taxRate / 100)
                      }
                    />
                  ))}
                {data.payrolls &&
                  data.payrolls.map((assignment, index) => (
                    <Assignment
                      type="payroll"
                      title={t("Payroll")}
                      label={t("Payroll")}
                      onDelete={handleDelRelation}
                      data={assignment}
                      amount={assignment.amount}
                      key={"payroll-" + assignment.id}
                    />
                  ))}
              </Box>
            </Box>
          </Box>
        )}
      </Collapse>
      <ExoDialog
        onClose={() => setOpenForm(false)}
        className="flex justify-center items-center"
        open={openForm}
      >
        <ExoVariants variants={variants} />
      </ExoDialog>
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

const Assignment = ({ data, onDelete, Icon, amount, label, type, title }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();

  return (
    <Box
      className="flex flex-col gap-1 p-1 w-full md:w-96"
      sx={{
        borderRadius: "10px",
        borderWidth: "1px",
        borderColor: colors.grey[300],
        backgroundColor: colors.glass,
        maxWidth: "100%",
      }}
    >
      <Box className="flex flex-row justify-between gap-1 px-2">
        <Box className="flex gap-2">
          <Typography>
            <b>{data.id}</b>
          </Typography>
          |<Typography>{title}</Typography>
        </Box>
        <Typography className="pl-2">
          <b>
            <MoneyLabel money={amount} />
          </b>
        </Typography>
      </Box>
      <Box className=" flex flex-row gap-2 justify-between">
        <a href={data.file && data.file.temporaryUrl} target="_blank">
          <Button className="flex flex-row gap-1">
            <VisibilityIcon />
            {label}
          </Button>
        </a>

        <Button
          onClick={() => onDelete(data, type)}
          color="error"
          variant="outlined"
          className="flex flex-row gap-1"
        >
          <RemoveCircleIcon />
          {t("Del Relation")}
        </Button>
      </Box>
    </Box>
  );
};

const InfoText = ({ label, value, hidden }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  if (hidden || !value) return;
  return (
    <Box className="mb-2 block">
      <Typography
        className="block"
        variant="p"
        sx={{ color: colors.grey[700] }}
      >
        <b>{t(label)}</b>
      </Typography>
      <Typography
        className="block"
        variant="p"
        sx={{ color: colors.grey[700] }}
      >
        {value}
      </Typography>
    </Box>
  );
};

const TransactionInfoList = [
  {
    label: "ID",
    key: "id",
  },
  {
    label: "IBAN",
    key: "transactionPartnerIban",
  },
  {
    label: "Booking Text",
    key: "bookingText",
  },
];

export const TransactionsSkeleton = () => {
  return (
    <Box className="flex flex-col gap-1">
      {[...Array(30)].map((e, i) => (
        <Skeleton key={i} height={50} sx={{ transform: "scale(1)" }} />
      ))}
    </Box>
  );
};

const NewReceiptRelation = ({ onSubmit }) => {
  function handleSubmit(receipt) {
    onSubmit({ receipt, type: "receipt" });
  }

  return (
    <SelectReceipt
      filter={"assignment[doesnothave][]=transactions"}
      onSelect={handleSubmit}
      className=" w-full"
    />
  );
};

const NewInvoiceRelation = ({ onSubmit, formRef, onClose }) => {
  function handleSubmit(invoice) {
    onSubmit({ invoice, type: "invoice" });
  }

  return (
    <SelectInvoice
      filter={"assignment[doesnothave][]=transactions"}
      onSelect={handleSubmit}
      className=" w-full"
    />
  );
};

const NewPayrollRelation = ({ onSubmit }) => {
  function handleSubmit(payroll) {
    onSubmit({ payroll, type: "payroll" });
  }

  return (
    <SelectPayroll
      filter={
        "assignment[doesnothave][]=transactions&sortBy=startDate&sortOrder=asc"
      }
      onSelect={handleSubmit}
      className=" w-full"
    />
  );
};

const TransactionToolbar = ({ onDelete, onEdit, transaction }) => {
  const config = useConfig();

  function handleDelete() {
    DBRequest({
      config,
      path: `transactions/${transaction.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(transaction),
    });
  }

  return (
    <Box>
      <IconButton onClick={() => onEdit(transaction)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default Transaction;
