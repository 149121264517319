import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import calcColorTheme from "./CalcColorTheme";
import Cookies from "universal-cookie";
import { themeSettings } from "./muiTheme";

//context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const cookies = new Cookies();
  const isLowPerformance = cookies.get("isLowPerformance") === "true";
  // color mode
  const [mode, setMode] = useState(
    cookies.get("colorMode") ? cookies.get("colorMode") : "light"
  );
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => {
          const newMode = prev === "light" ? "dark" : "light";
          updateTheme(newMode);
          return newMode;
        });
      },
    }),
    []
  );

  // color theme
  var storedColors;
  storedColors = {
    primary: "#4b6e7a",
    grey: "#70738a",
    greenAccent: "#8c9e18",
    redAccent: "#ab4141",
    blueAccent: "#3273bc",
  };
  if (cookies.get("colorTheme") !== "EMPTY") {
    storedColors = cookies.get("colorTheme");
  }

  const [colorTheme, setColorTheme] = useState(
    calcColorTheme(storedColors, mode)
  );

  function updateTheme(newMode) {
    setColorTheme(calcColorTheme(storedColors, newMode));
  }

  // create theme
  const theme = useMemo(
    () => createTheme(themeSettings(mode, colorTheme, isLowPerformance)),
    [mode]
  );

  // change Theme Settings

  return [theme, colorMode];
};
