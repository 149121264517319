import { API_POST, DBRequest, useConfig } from "../../api/api";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExoForm from "./ExoForm";
import * as Yup from "yup";
import Loader from "../special/Loader";
import { useEffect, useState } from "react";
import SuccessCheck from "../special/SuccessCheck";
import FailureCross from "../special/FailureCross";
import ExoDialog from "./ExoDialog";

const ExoResendMail = ({ recipient, type, id, label, open, onClose }) => {
  const config = useConfig();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [successfull, setSuccessfull] = useState(null);
  const [message, setMessage] = useState("");
  function resendMail(data) {
    DBRequest({
      config,
      path: `${type}/${id}/resend-email`,
      method: API_POST({ delivery: data.delivery }),
      onLoading: setIsLoading,
      onResponse: handleSendSuccess,
      onError: handleSendFailed,
    });
  }

  useEffect(() => {
    setSuccessfull(null);
  }, [id, type]);

  function handleSendSuccess(data) {
    setSuccessfull(true);
    setMessage(data.message);
  }

  function handleSendFailed(data) {
    setSuccessfull(false);
    setMessage(data.message);
  }

  const validationSchema = Yup.object().shape({
    delivery: Yup.object().shape({
      deliveryMail: Yup.string()
        .email(t("Invalid email format"))
        .required(t("Delivery mail id is required")),
      deliveryName: Yup.string().required(t("Delivery name id is required")),
    }),
  });

  return (
    <ExoDialog open={open} limitWidth>
      <Loader active={isLoading} />
      <Box
        sx={{ width: "900px", height: "80vh", maxWidth: "95vw" }}
        className="p-4 flex flex-col justify-between h-full"
      >
        {successfull !== null ? (
          <Box className="flex h-full flex-col">
            <Box className="flex h-full justify-center items-center flex-col">
              {successfull ? <SuccessCheck /> : <FailureCross />}
              <Typography variant="h4" textAlign="center">
                {successfull
                  ? t("Email successfully sent")
                  : t("Email could not be sent")}
              </Typography>
            </Box>
            <Box className="flex flex-row-reverse justify-between items-center">
              {!successfull && (
                <Button
                  onClick={() => setSuccessfull(null)}
                  variant="contained"
                  color={"warning"}
                >
                  {t("Retry")}
                </Button>
              )}
              <Button onClick={onClose} color={"error"} variant="outlined">
                {t("Close")}
              </Button>
            </Box>
          </Box>
        ) : (
          <ExoForm
            className="h-full flex flex-col justify-between"
            fields={[
              {
                key: "delivery",
                type: "delivery",
                label: t("Delivery"),
                options: ["recipientMail", "contactMail", "customMail"],
              },
            ]}
            startDataCollection={{ recipient: recipient }}
            onSubmit={resendMail}
            onCancle={onClose}
            validationSchema={validationSchema}
          />
        )}
      </Box>
    </ExoDialog>
  );
};

export default ExoResendMail;
