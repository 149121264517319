import {
  Box,
  Button,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import MediaGridItem from "./MediaGridItem";
import { DBUploadData, useConfig } from "../../../api/api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ExoAlert from "../ExoAlert";

const UploadPreview = ({
  filesToUpload,
  onRemove,
  onUpload,
  onCancle,
  fileType,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const [progress, setProgress] = useState(100);
  const [finished, setFinished] = useState(true);
  const [alert, setAlert] = useState(null);

  const config = useConfig();

  function handleUpload() {
    setAlert(null);
    const formData = new FormData();

    if (fileType) formData.append(`fileType`, fileType);

    filesToUpload.forEach((file, index) => {
      formData.append(`files[]`, file); // Use a unique field name for each file
    });
    DBUploadData({
      config,
      path: "files",
      formData: formData,
      onProgressChange: setProgress,
      onFinished: setFinished,
      onResponse: handleFinishedUpload,
      onError: handleError,
    });
  }

  function handleError(err) {
    setFinished(true);
    setAlert({ type: "error", message: err.response.data.message });
    console.log(err);
  }

  function handleFinishedUpload(data) {
    onUpload(data);
    onCancle();
  }

  return (
    <Box
      className="absolute top-0 left-0 p-1 h-full w-full backdrop-blur-lg overflow-y-auto"
      sx={{
        backgroundColor: colors.glass,
        transition: "background-color 0.2s ease-out",
      }}
    >
      {!finished ? (
        <Box
          className="flex flex-row gap-2 relative justify-center items-center h-full"
          sx={{
            height: "80vh",
            width: "90vw",
          }}
        >
          {progress < 100 ? (
            <Box className="flex flex-col gap-4 justify-center items-center w-full">
              <Box sx={{ width: "80%", maxWidth: "400px" }}>
                <LinearProgress
                  variant="determinate"
                  value={Math.round(progress)}
                  color="primary"
                />
              </Box>
              <Typography variant="h3">
                {t("Uploading")} {Math.round(progress)}%
              </Typography>
            </Box>
          ) : (
            <Box className="flex flex-col gap-4 justify-center items-center">
              <span className="loader"></span>
              <Typography variant="h3">{t("Processing Upload")}</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <>
          <Box className=" h-full ">
            <Box
              className="gap-2 w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 overflow-y-auto"
              sx={{
                maxHeight: "100%",
                paddingBottom: "49px",
              }}
            >
              {filesToUpload.map((item) => (
                <MediaGridItem
                  key={item.id}
                  item={{ ...item, thumbnailUrl: URL.createObjectURL(item) }}
                  onRemove={onRemove}
                />
              ))}
            </Box>
          </Box>
          <Box className="absolute bottom-0 left-0 w-full p-2 flex justify-between backdrop-blur-lg">
            <Button variant="contained" color="error" onClick={onCancle}>
              {t("Cancle")}
            </Button>
            <Button variant="contained" color="success" onClick={handleUpload}>
              {t("Upload")}
            </Button>
          </Box>
        </>
      )}
      <ExoAlert
        open={Boolean(alert)}
        alert={alert || {}}
        onClose={() => setAlert(null)}
        onSubmit={handleUpload}
      />
    </Box>
  );
};

export default UploadPreview;
