import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import TypeCompanyId from "./TypeCompanyId";
import TypeContactId from "./TypeContactId";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ContactsIcon from "@mui/icons-material/Contacts";
import { useTranslation } from "react-i18next";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import LabeledBox from "../../special/LabeledBox";

const TypeClient = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
}) => {
  console.log(dataCollection, dataCollection[`${field.key}Type`]);
  const [currentSelected, setCurrentSelected] = useState(null);
  const [type, setType] = useState(null);
  const { t } = useTranslation();
  function handleChange(key, value) {
    updateValidationOnChange(key, value ? { id: value } : null);
    setCurrentSelected(value);
  }

  function handleChangeType(event, newType) {
    if (newType !== type && newType) {
      setType(newType);
      setCurrentSelected(null);
      updateValidationOnChange(field.key, null);
    }
  }

  useEffect(() => {
    updateValidationOnChange(`${field.key}Type`, type);
    if (type === null) setType(dataCollection[`${field.key}Type`]);
  }, [dataCollection[field.key]]);

  return (
    <Box className="flex flex-row gap-2">
      <ToggleButtonGroup value={type} exclusive onChange={handleChangeType}>
        <ToggleButton value="company" sx={{ width: "53px" }}>
          <Tooltip title={t("Company")}>
            <ApartmentIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="contact" sx={{ width: "53px" }}>
          <Tooltip title={t("Contact")}>
            <ContactsIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="internal" sx={{ width: "53px" }}>
          <Tooltip title={t("Internal")}>
            <MoneyOffIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
      {type === "company" && (
        <TypeCompanyId
          updateValidationOnChange={handleChange}
          dataCollection={
            dataCollection[field.key]
              ? { [field.key]: dataCollection[field.key].id }
              : {}
          }
          errors={errors}
          field={{ ...field, label: t("Company") }}
          value={currentSelected}
        />
      )}
      {type === "contact" && (
        <TypeContactId
          updateValidationOnChange={handleChange}
          dataCollection={
            dataCollection[field.key]
              ? { [field.key]: dataCollection[field.key].id }
              : {}
          }
          errors={errors}
          field={{ ...field, label: t("Contact") }}
          value={currentSelected}
        />
      )}
      {type === "internal" && (
        <LabeledBox className="w-full" label={t("Internal")} />
      )}
    </Box>
  );
};

export default TypeClient;
