import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../api/api";
import Feedback from "../special/Feedback";
import ExoForm from "../exo/ExoForm";
import Loader from "../special/Loader";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const NewUser = ({ onClose = () => {}, onCreate = () => {}, startData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const config = useConfig();
  const { t } = useTranslation();
  const defaultStartData = { color: "#5e9ac8" };
  const [startUpData, setStartUpData] = useState(defaultStartData);

  useEffect(() => {
    if (startData) setStartUpData(startData);
  }, [startData]);

  function handleSubmit(data) {
    setIsLoading(true);
    DBRequest({
      config,
      path: startData && startData.id ? "users/" + startData.id : "users",
      method: startData && startData.id ? API_PATCH(data) : API_POST(data),
      onFeedback: handleFeedback,
      onResponse: handleResponse,
    });
  }

  function handleResponse(res) {
    onCreate(res.data);
    onClose();
  }

  const fields = [
    {
      label: `${t("Color")} (${t("optional")})`,
      type: "color",
      key: "color",
    },
    {
      label: `${t("Profile Picture")} (${t("optional")})`,
      type: "profilePicture",
      key: "picture",
    },
    {
      label: t("Access"),
      type: "accessId",
      key: "accessId",
    },
    {
      label: t("Salutation"),
      type: "text",
      key: "salutation",
    },
    {
      label: t("First Name"),
      type: "text",
      key: "firstName",
    },
    {
      label: t("Last Name"),
      type: "text",
      key: "lastName",
    },
    {
      label: t("Mail"),
      type: "text",
      key: "email",
    },
    {
      label: t("Phone"),
      type: "text",
      key: "phone",
    },
    {
      label: t("Is CEO"),
      type: "checkbox",
      key: "isCeo",
    },
  ];

  const validationSchema = Yup.object().shape({
    accessId: Yup.number().required("Access Role is required"),
    salutation: Yup.string().required("Salutation is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
  });

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleFeedback(text, type) {
    setIsLoading(false);
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
    if (type === "success") onClose();
  }
  return (
    <Box
      className=" flex flex-col gap-2 p-4 max-w-full"
      sx={{ width: "500px" }}
    >
      <ExoForm
        startDataCollection={startUpData}
        header={startData ? t("Edit Employee") : t("New Employee")}
        fields={fields}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onCancle={onClose}
        alert={
          startData
            ? {}
            : {
                type: "info",
                message: t(
                  "The new user receives an e-mail to the specified e-mail address with which they can set a password."
                ),
              }
        }
      />
      <Feedback setState={setAlertState} state={alertState} />
      <Loader active={isLoading} message={t("Sending Data...")} />
    </Box>
  );
};

export default NewUser;
