import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ExoAvatar from "../../ExoAvatar";
import { renderHours } from "../../../../scenes/time-tracking/worktime";

const TimeBox = ({
  time,
  selected,
  marginLeft,
  dateToPixel,
  onClick,
  edit,
  mobileView,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const startMoment = moment(time.timeStart);
  const endMoment = moment(time.timeEnd);
  const timeInMinutes = endMoment.diff(startMoment, "minutes");

  const top = dateToPixel(time.timeStart);
  const height = Math.abs(dateToPixel(time.timeEnd) - top);

  const padding = mobileView ? 2 : 10;

  const heightThreshold = 80;

  if (time.method === "delete") return;
  return (
    <Box
      className="absolute px-2 w-full cursor-pointer"
      onMouseDown={(e) => e.preventDefault()}
      onClick={() => onClick(time)}
      style={{
        display: selected ? "none" : "flex",
        top: `${top}px`,
        height: `${height}px`,
        width: "100%",
        zIndex: 1,
        paddingLeft: `${marginLeft + padding}px`,
        paddingRight: `${padding}px`,
      }}
    >
      <Box
        className="relative  h-full w-full rounded-lg flex flex-col backdrop-blur-sm px-1 justify-center items-center"
        onMouseDown={(e) => e.preventDefault()}
        sx={{
          bgcolor: edit
            ? `${colors.blueAccent[400]}60`
            : `${colors.primary[500]}60`,
          borderWidth: "1px",
          borderColor: edit ? colors.blueAccent[400] : colors.primary[600],
        }}
      >
        <ExoAvatar
          picture={
            time.project.client.logo && time.project.client.logo.temporaryUrl
          }
          name={
            time.project.clientType === "company"
              ? time.project.client.name
              : `${time.project.client.firstName} ${time.project.client.lastName}`
          }
          size={height < 30 ? height : 30}
          type={time.project.clientType}
        />
        {!mobileView && height > heightThreshold && (
          <>
            {time.project ? (
              <Typography variant="h5" textAlign="center">
                {time.project.name}
              </Typography>
            ) : (
              <Typography variant="h5" textAlign="center">
                {t("INTERNAL")}
              </Typography>
            )}
          </>
        )}
        {!mobileView && height > 21 && (
          <Typography
            variant="h5"
            textAlign="center"
            className="monospace absolute right-1"
            sx={{
              bottom: `${height < 29 ? (height - 21) / 2 : 4}px`,
            }}
          >
            {renderHours(timeInMinutes)} h
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TimeBox;
