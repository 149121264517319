import { Fragment, useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { tokens } from "../global/theme/tokens";
import { API_GET, useConfig } from "../api/api";
import Cookies from "universal-cookie";
import { Box } from "@mui/system";
import { useTheme, Typography } from "@mui/material";
import FingerprintOutlinedIcon from "@mui/icons-material/FingerprintOutlined";
import Layout from "../Layout";
import { useTranslation } from "react-i18next";
import { AppTimeTrackingProvider } from "../components/time-tracking/AppTimeProvider";

const RequireAuth = ({ debug = true, noLayout }) => {
  const cookies = new Cookies();
  const location = useLocation();
  const [authorized, setAuthorized] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  const config = useConfig();

  useEffect(() => {
    if (config) {
      fetch(config.API_AUTH, API_GET).then((res) => {
        if (!res.ok) {
          setIsChecking(false);
          delAllCookies();
        } else {
          setAuthorized(true);
          setIsChecking(false);
        }
      });
    }
  }, [config]);
  if (location.pathname !== "/install" && cookies.get("initialSetup"))
    return <Navigate to="/install" state={{ from: location }} replace />;

  return isChecking ? (
    <AuthScreen />
  ) : (
    <AppTimeTrackingProvider>
      {authorized ? (
        noLayout ? (
          <Outlet />
        ) : (
          <Layout>
            <Outlet />
          </Layout>
        )
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </AppTimeTrackingProvider>
  );
};

function delAllCookies() {
  const cookies = new Cookies();
  const currentCookies = cookies.getAll();
  for (const [key, value] of Object.entries(currentCookies)) {
    if (key !== "colorMode") {
      cookies.remove(key);
    }
  }
}

const AuthScreen = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();

  return (
    <Box
      className="w-full h-full absolute top-0 left-0 z-50 flex justify-center items-center flex-col"
      sx={{
        backgroundColor: colors.glassSolid,
        "& svg": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <div className="w-40 px-2 py-4 relative">
        <Box
          id="scanner"
          className="w-full top-0 left-0 rounded-full"
          sx={{
            height: "3px",
            backgroundColor: colors.greenAccent[500],
            boxShadow: "0 0 20px 2px " + colors.greenAccent[500],
          }}
        ></Box>
        <FingerprintOutlinedIcon />
      </div>
      <Typography variant="h2">{t("Authenticate")}</Typography>
    </Box>
  );
};

export default RequireAuth;
