import React from "react";
import ExoAvatar from "../../ExoAvatar";
import moment from "moment";
import Typography from "@mui/material/Typography";
import { renderHours } from "../../../../scenes/time-tracking/worktime";
import { Box, IconButton, useTheme } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import { SettingsPopover } from "../../project/SettingsPopover";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

export const TimeElement = ({ time }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  if (time.method === "delete") return;

  const client = time.project.client;
  const name =
    time.project.clientType === "company"
      ? client.name
      : `${client.firstName} ${client.lastName}`;

  const timeDiff = moment(time.timeEnd).diff(moment(time.timeStart), "m");

  const timeLabel =
    time.comment || (time.workPackage && time.workPackage.title);

  const settings = [
    {
      Icon: DeleteIcon,
      label: t("Delete"),
      onClick: () => {},
      color: "error",
    },
  ];

  return (
    <Box
      className="flex w-full items-center justify-between rounded-md px-2 py-1"
      sx={{ bgcolor: colors.card }}
    >
      <Box className="flex items-center gap-2">
        <ExoAvatar
          size="30px"
          picture={client.logo && client.logo.temporaryUrl}
          name={name}
          type={time.project.clientType}
        />
        <Box className="flex flex-col">
          <Typography sx={{ fontSize: "12px" }} variant="h6">
            {timeLabel ? time.project.name : ""}
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            {timeLabel || time.project.name}
          </Typography>
        </Box>
      </Box>
      <Box className="flex items-center">
        <Typography className="monospace">{renderHours(timeDiff)}</Typography>
        {false && <SettingsPopover settings={settings} />}
      </Box>
    </Box>
  );
};
