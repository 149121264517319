import { useTheme, Box, Button } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import SingleFileUpload from "../upload/SingleFileUpload";
import { DBUploadData, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import Feedback from "../special/Feedback";
import ExoForm from "../exo/ExoForm";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import findDifferences from "../exo/findDifferencesInDataCollection";
import Loader from "../special/Loader";

const NewReceipt = ({ onClose, receipt, debug, onCreate, open }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const exoFormRef = useRef(null);
  const singeUploadRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const config = useConfig();

  const defaultValues = {
    origin: "domestic",
    receipt: { taxRate: "19", amount: 0 },
  };
  const [usedValues, setUsedValues] = useState(defaultValues);
  useEffect(() => {
    setUsedValues(receipt || defaultValues);
  }, [receipt, open]);

  function handleSave(data) {
    var rawData = receipt && receipt.id ? findDifferences(receipt, data) : data;
    const formData = new FormData();

    if (receipt && receipt.file) {
      if (file) {
        if (Object.keys(file).length) {
          formData.append(`file`, file);
        } else {
          formData.append(`file`, null);
        }
      }
    } else {
      if (file && Object.keys(file).length > 0) formData.append(`file`, file);
    }

    if (rawData.title) formData.append(`title`, rawData.title);
    if (rawData.receiptDate)
      formData.append(`receiptDate`, rawData.receiptDate);
    if (rawData.receiptNr) formData.append(`receiptNr`, rawData.receiptNr);
    if (rawData.dueDate) formData.append(`dueDate`, rawData.dueDate);
    formData.append(`origin`, rawData.origin || defaultValues.origin);
    if (rawData.receipt)
      formData.append(`receipt`, JSON.stringify(rawData.receipt));
    if (rawData.transaction)
      formData.append(`transaction`, JSON.stringify(rawData.transaction));

    if (receipt && receipt.id) formData.append("_method", "PATCH");

    const path = receipt && receipt.id ? `receipts/${receipt.id}` : "receipts";
    setIsLoading(true);
    DBUploadData({
      config,
      path,
      formData: formData,
      onResponse: handleResponse,
    });
  }

  function handleResponse(response) {
    setIsLoading(false);
    if (response.status === 200) {
      onCreate(response.data.data);
    }
    if (response.status === 201) {
      onCreate(response.data.data);
      setAlertState({
        alertOpen: true,
        alertText: response.data.message,
        alertType: "success",
      });
      exoFormRef.current.reset();
      singeUploadRef.current.reset();
    } else {
      setAlertState({
        alertOpen: true,
        alertText: response.response.data.message,
        alertType: "error",
      });
    }
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });

  //--------------------------

  const fields = [
    {
      label: `${t("Transaction")} (${t("optional")})`,
      type: "transaction",
      key: "transaction",
      colspan: 2,
    },
    {
      label: t("Title"),
      type: "text",
      key: "title",
    },
    {
      label: t("Receipt Number"),
      type: "text",
      key: "receiptNr",
    },
    {
      label: t("Receipt Date"),
      type: "date",
      key: "receiptDate",
    },
    {
      label: `${t("Due Date")} (${t("optional")})`,
      type: "date",
      key: "dueDate",
    },
    {
      label: t("Reverse Charge Bill"),
      type: "checkbox",
      key: "reverseCharge",
    },
    {
      label: t("Origin"),
      type: "customSelect",
      key: "origin",
      options: [
        {
          label: t("Domestic"),
          value: "domestic",
        },
        {
          label: t("EU"),
          value: "eu",
        },
        {
          label: t("Foreign"),
          value: "foreign",
        },
      ],
    },
    {
      label: t("Receipt Position"),
      key: "receipt",
      type: "receiptPosition",
      colspan: 2,
    },
  ];

  // custom startup
  var oldTitle;
  var oldTransaction;
  function handleChange(data, isValid) {
    const receipt = data.receipt;
    //amount
    if (
      data.transaction &&
      data.transaction.id &&
      data.transaction.id !== oldTransaction
    ) {
      oldTransaction = data.transaction.id;
      exoFormRef.current.updateDataCollection({
        receipt: { ...receipt, amount: data.transaction.openAmount * -1 },
      });
      return;
    }
    // Title
    if (oldTitle != data.title) {
      exoFormRef.current.updateDataCollection({
        receipt: { ...receipt, position: data.title },
      });
      oldTitle = data.title;
      return;
    }
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("Title is required")),
    receiptDate: Yup.string().required(t("Start Date is required")),
    origin: Yup.string().required(t("Origin is required")),
    receiptNr: Yup.string().required(t("Receipt Ner is required")),
    receipt: Yup.object().shape({
      amount: Yup.string().required(t("Amount is required")),
      position: Yup.string().required(t("Position is required")),
      taxAssignmentId: Yup.number().required(t("Tax Assignment is required")),
      taxRate: Yup.number().required(t("Tax Rate is required")),
      costCenter: Yup.object().shape({
        id: Yup.string().required(t("Cost Center is required")),
      }),
    }),
  });

  // preload file
  useEffect(() => {
    if (receipt && receipt.file) {
      const newFileData = {
        id: receipt.file.id,
        lastModified: 0,
        name: receipt.file.title,
        path: receipt.file.temporaryUrl,
        size: receipt.file.fileSize,
        type: receipt.file.mimeType,
        webkitRelativePath: "",
      };
      singeUploadRef.current.setFileData(newFileData);
    } else {
      singeUploadRef.current.reset();
    }
  }, [receipt]);

  return (
    <Box
      className="w-full h-full overflow-y-auto flex flex-col md:flex-row gap-2 relative px-2 py-4 md:px-2 md:py-2 mx-auto"
      sx={{
        maxWidth: "1500px",
      }}
    >
      <Box className="col-span-1 md:col-span-2 flex items-center justify-center  overflow-y-auto py-0 md:py-10 h-96 md:h-full w-full">
        <SingleFileUpload
          debug={debug}
          ref={singeUploadRef}
          setFile={(data) => {
            setFile(data);
          }}
          uploadedMedia={file}
        />
      </Box>
      <Box
        className="col-span-1 md:col-span-3 flex flex-col gap-4 p-0 md:p-1 rounded-lg"
        sx={{
          minWidth: "550px",
          maxWidth: "550px",
          "@media only screen and (max-width: 767px)": {
            minWidth: "unset",
            maxWidth: "100%",
            width: "100%",
          },
        }}
      >
        <ExoForm
          className="grid grid-cols-2 gap-4"
          validationSchema={validationSchema}
          ref={exoFormRef}
          startDataCollection={usedValues}
          noDifferences={Boolean(receipt)}
          fields={fields}
          onChange={handleChange}
          onSubmit={handleSave}
          header={t("New Receipt")}
          onCancle={onClose}
          useStartDataOnReset
          debug={debug}
          submitText={receipt ? t("Update") : t("Create")}
        />
      </Box>

      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

export default NewReceipt;
