import { Box } from "@mui/material";
import ExoForm from "../ExoForm";
import { useEffect, useRef } from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const ModifyTime = ({ time, onSubmit, onCancle, userId }) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    timeStart: Yup.string().required(t("Date is required")),
    timeEnd: Yup.string().required(t("Date is required")),
    project: Yup.object().shape({
      id: Yup.number().required(t("Project is required")),
    }),
  });

  const fields = [
    {
      label: t("Project"),
      key: "project",
      type: "project",
      apiUrl:
        userId && `projects?userId[eq]=${userId}&perPage=100&status[eq]=0`,
    },
    {
      label: `${t("Phase")} (${t("optional")})`,
      key: "phase",
      type: "phase",
      projectKey: "project",
    },
    {
      label: `${t("Work Package")} (${t("optional")})`,
      key: "workPackage",
      type: "workPackage",
      phaseKey: "phase",
    },
    {
      label: t("Time Start"),
      key: "timeStart",
      type: "time",
    },
    {
      label: t("Time End"),
      key: "timeEnd",
      type: "time",
    },
    {
      label: t("Activities"),
      key: "comment",
      type: "description",
    },
  ];

  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current === null) return;
    const startDataCollection = {
      project: time.project || null,
      method: time.id ? "update" : "add",
      id: time.id,
      taskGroup: time.taskGroup,
      timeStart: time.timeStart,
      timeEnd: time.timeEnd,
      comment: time.comment,
    };

    formRef.current.reset();
    formRef.current.updateDataCollection(startDataCollection);
  }, [time]);

  return (
    <Box className="p-2">
      <ExoForm
        header={time.id ? t("Update Time") : t("Create Time")}
        ref={formRef}
        fields={fields}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        onCancle={onCancle}
      />
    </Box>
  );
};
export default ModifyTime;
