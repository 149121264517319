import { useState } from "react";
import { tokens } from "./theme/tokens";
import { NavLink } from "react-router-dom";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import Cookies from "universal-cookie";
import Zoom from "@mui/material/Zoom";
import Logout from "../auth/Logout";
import ExoAvatar from "../components/exo/ExoAvatar";
import menuItemList from "./menuItemList";

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const cookies = new Cookies();
  const firstName = cookies.get("firstName");
  const lastName = cookies.get("lastName");
  const profilePicture = cookies.get("profilePicture");
  const color = cookies.get("color");
  const position = cookies.get("position");
  const [isOpen, setIsOpen] = useState(true);
  function toggle() {
    setIsOpen(!isOpen);
  }

  const [userStatusColor, setUserStatusColor] = useState(
    colors.greenAccent[500]
  );

  return (
    <nav
      id={isOpen ? "navCollapsed" : "navExpanded"}
      className="hidden sm:block h-full relative transition backdrop-blur-lg overflow-hidden"
      style={{ backgroundColor: colors.glass }}
    >
      <Box className="w-full relative h-20 flex items-center justify-center">
        <img
          id="nav-logo"
          src={
            theme.palette.mode === "dark"
              ? "../../assets/svg/exopaper-text-darkmode.svg"
              : "../../assets/svg/exopaper-text.svg"
          }
          alt="ExoPaper"
          className=" pr-14 pl-2"
        />
        <button
          className={isOpen ? "hamburger" : "hamburger is-active"}
          onClick={toggle}
        >
          <span className="hamburger-box">
            <span
              className="hamburger-inner b1"
              style={{
                backgroundColor: colors.primary[700],
              }}
            ></span>
            <span
              className="hamburger-inner b2"
              style={{
                backgroundColor: colors.primary[700],
              }}
            ></span>
            <span
              className="hamburger-inner b3"
              style={{
                backgroundColor: colors.primary[700],
              }}
            ></span>
          </span>
        </button>
      </Box>
      <NavLink to="/profile">
        <ExoAvatar
          id="nav-profile-picture"
          className="rounded-full mb-1 mx-auto"
          picture={profilePicture}
          name={firstName + " " + lastName}
          color={color}
          sx={{ borderColor: userStatusColor }}
        />

        <Box
          id="nav-user-info"
          className="flex flex-col justify-center items-center min-w-max gap-2"
        >
          <Box className="flex flex-col justify-center items-center">
            <Typography className="h-6 nav-text" variant="h4">
              {firstName + " " + lastName}
            </Typography>
            <Typography
              className="h-6 nav-text"
              variant="h5"
              color={colors.blueAccent[500]}
            >
              {position}
            </Typography>
          </Box>
        </Box>
      </NavLink>
      <Box
        id="nav-menu"
        className="flex justify-start flex-col overflow-y-visible overflow-x-hidden"
      >
        {menuItemList().map((group, index) => (
          <MenuGroup group={group} key={group.title} />
        ))}
        <Logout />
      </Box>
    </nav>
  );
};

const MenuGroup = ({ group }) => {
  const hasHiddenFalse = group.items.some((item) => item.hidden === false);

  if (!hasHiddenFalse) return;

  return (
    <>
      <MenuTitle>{group.title}</MenuTitle>
      {group.items.map((item, index) => (
        <MenuItem item={item} key={group.title + index} />
      ))}
    </>
  );
};

const MenuItem = ({ item }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  if (item.hidden) return;

  return (
    <NavLink to={item.to} activeclassname="active" className="menu-item-link">
      <Box
        className="flex flex-row justify-start items-center gap-2 py-0.5 menu-item"
        sx={{
          "& p": {
            color: colors.grey[600] + "!important",
          },
          "& svg": {
            color: colors.grey[600],
          },
          ":hover": {
            cursor: "pointer",
            "& p": {
              color: colors.grey[900] + "!important",
            },
            "& svg": {
              color: colors.grey[900],
            },
          },
          ".active & svg": {
            color: colors.grey[100],
          },
          ".active & p": {
            color: colors.grey[100] + "!important",
          },
        }}
      >
        <Tooltip
          title={item.title}
          placement="right"
          arrow
          TransitionComponent={Zoom}
        >
          <Box
            className="flex flex-row gap-2 px-1.5 py-1.5 nav-item rounded-full w-full"
            sx={{
              ".active & ": {
                backgroundColor: colors.primary[600],
              },
            }}
          >
            {item.icon}
            <Typography className="nav-text">{item.title}</Typography>
          </Box>
        </Tooltip>
      </Box>
    </NavLink>
  );
};

const MenuTitle = ({ children }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box className="relative">
      <Typography
        className="py-2 pl-2 nav-title relative"
        variant="h6"
        color={colors.grey[600]}
      >
        {children}
      </Typography>
      <Box
        className="absolute top-1/2 left-0 nav-divider"
        style={{
          backgroundColor: colors.primary[600],
          marginLeft: "6px",
          width: "45px",
        }}
      ></Box>
    </Box>
  );
};

export default Sidebar;
