import { Box, useTheme, Typography, Button } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import TypeDivider from "../../../form/form-fields/TypeDivider";
import { useConfig } from "../../../../api/api";
import { useEffect, useState } from "react";
import { PositionNumber } from "./PositionNumber";
import { useTranslation } from "react-i18next";
import CalculateIcon from "@mui/icons-material/Calculate";
import { numberToString } from "../../../special/numberConverter";

export const PositionPreCalculation = ({
  data,
  onChange,
  label,
  onFocus,
  position,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();
  const config = useConfig();

  const [calculatedPrice, setCalculatedPrice] = useState(0);

  useEffect(() => {
    if (config) onChange(config.general.defaultMarkupRate, "markupRate");
  }, [config]);

  function handleChange(value, key) {
    const newPrice = calcPrice({ ...position, [key]: value });
    setCalculatedPrice(newPrice);
    onChange(value, key);
  }

  function calcNewPrice() {
    onChange(calcPrice());
  }

  function calcPrice(newPosition) {
    const markupPrice =
      Math.round(
        parseFloat(newPosition.purchasePrice) *
          ((parseFloat(newPosition.markupRate) +
            parseFloat(newPosition.specialMarkupRate || 0)) /
            100) *
          100
      ) / 100;

    const newPrice = markupPrice + parseFloat(newPosition.purchasePrice);

    if (newPrice !== NaN) return newPrice;

    return 0;
  }

  return (
    <Box className="col-span-2 md:col-span-3 lg:col-span-4">
      <TypeDivider color={colors.grey[600]} width={"1px"} />
      <Typography className="pt-3">
        {t("Calculation of sales price")}
      </Typography>
      <Box className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-3">
        <PositionNumber
          data={position.purchasePrice || 0}
          label={"Purchase Price"}
          onFocus={onFocus}
          onChange={(e) => handleChange(e, "purchasePrice")}
          unit={config ? config.currency.currencySymbol : ""}
        />
        <PositionNumber
          data={position.markupRate || 0}
          label={"Markup Rate"}
          onFocus={onFocus}
          onChange={(e) => handleChange(e, "markupRate")}
          unit={"%"}
        />
        <PositionNumber
          data={position.specialMarkupRate || 0}
          label={"Special Markup Rate"}
          onFocus={onFocus}
          onChange={(e) => handleChange(e, "specialMarkupRate")}
          unit={"%"}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={calcNewPrice}
          className="flex gap-4"
        >
          <CalculateIcon />
          {`${
            calculatedPrice
              ? numberToString(calculatedPrice, 2)
              : numberToString(0, 2)
          } ${config ? config.currency.currencySymbol : ""} ${t("transfer")}`}
        </Button>
      </Box>
    </Box>
  );
};
