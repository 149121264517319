import { Box, Button, Typography } from "@mui/material";
import Header from "../../components/special/Header";
import { useTranslation } from "react-i18next";
import TypeSkeleton from "../../components/form/form-fields/TypeSkeleton";
import EmployeeCard from "../employees/EmployeeCard";
import { t } from "i18next";
import { TimeAccountCard } from "../time-tracking/TimeAccountCard";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { Link } from "react-router-dom";
import useFetch from "../../api/useFetch";
import CardContainer from "../../components/cards/layout/CardContainer";
import { TimeLabel } from "../../components/label/TimeLabel";
import moment from "moment";
import { renderHours } from "../time-tracking/worktime";

const TimeManager = () => {
  const { t } = useTranslation();

  const { apiData } = useFetch("users/time-tracking");

  return (
    <>
      <Header title={t("Time Manager")} />
      {apiData ? (
        <Box className="flex flex-col gap-4 h-full pb-2 md:pb-0 overflow-auto px-1 sm:px-0">
          {apiData.data.map((data) => (
            <User key={data.id} data={data} />
          ))}
        </Box>
      ) : (
        <TypeSkeleton n={10} />
      )}
    </>
  );
};

const User = ({ data }) => {
  return (
    <Box className="flex flex-col md:flex-row gap-2">
      <EmployeeCard employee={data} />
      <Box className=" flex flex-row md:justify-between gap-2">
        {data.activeTimeTracking && (
          <ActiveTimeTracking activeTime={data.activeTimeTracking} />
        )}
        <TimeAccountCard
          minutes={data.timeAccountMinutes}
          label={t("Time Account")}
          size="small"
          width="150px"
          height="80px"
          color="glass"
        />

        <TimeAccountCard
          minutes={data.minutesThisMonth}
          label={t("This Month")}
          size="small"
          height="80px"
          width="150px"
          color="glass"
        />
        <Link to={`/time-manager/${data.id}`} className="flex">
          <Button
            variant="contained"
            color="secondary"
            className=" backdrop-blur-lg"
          >
            <QueryStatsIcon />
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

const ActiveTimeTracking = ({ activeTime }) => {
  const { t } = useTranslation();

  const currentMinutes =
    moment().diff(moment(activeTime.timeStart)) / (1000 * 60);

  return (
    <CardContainer
      className=" hidden sm:flex md:hidden lg:flex backdrop-blur-lg w-40"
      sx={{ gap: "0px" }}
    >
      <Typography variant="h3">{renderHours(currentMinutes)}</Typography>
      <Typography variant="p">{t("Working Since")}</Typography>
    </CardContainer>
  );
};

export default TimeManager;
