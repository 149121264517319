import {
  Box,
  Button,
  IconButton,
  Popover,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { tokens } from "../../global/theme/tokens";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterGroup from "./filter/FilterGroup";
import OrderGroup from "./filter/OrderGroup";

const defaultOptions = {
  filter: {
    label: "Filter",
    items: [
      {
        label: "No Invoices",
        value: "assignment[doesnothave][]=invoices",
        active: false,
      },
      {
        label: "No Receipts",
        value: "assignment[doesnothave][]=receipts",
        active: false,
      },
    ],
  },
  order: {
    label: "Order",
    items: [
      {
        label: "By Booking Date",
        value: "bookingDate",
        active: false,
      },
      {
        label: "By Amount",
        value: "amount",
        active: false,
      },
    ],
  },
};

const ExoAPIFilter = ({
  onChange,
  filters = defaultOptions,
  currentFilters,
  Icon = FilterAltIcon,
  label,
  noClear,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  // item handeling
  const [activeFilters, setActiveFilters] = useState([]);
  const [activeOrder, setActiveOrder] = useState({});

  function handleFilterChange(groupKey, itemIndex) {
    const currentItem = filters[groupKey].items[itemIndex];

    const itemIndexInActiveFilters = activeFilters.findIndex(
      (item) => item.value === currentItem.value
    );
    if (itemIndexInActiveFilters !== -1) {
      //item exists and needs to be removed
      const updatedItems = [...activeFilters];
      updatedItems.splice(itemIndexInActiveFilters, 1);
      setActiveFilters(updatedItems);
    } else {
      //item does not exsist. needs to be added
      setActiveFilters([...activeFilters, { ...currentItem }]);
    }
  }

  function handleOrderChange(orderParam, orderDir) {
    setActiveOrder({
      orderParam: orderParam,
      orderDir: orderDir ? "asc" : "desc",
    });
  }

  function convertToString() {
    var string = "";
    activeFilters.forEach((filter) => {
      string += `${filter.value}&`;
    });
    if (activeOrder.orderParam)
      string += `sortOrder=${activeOrder.orderDir ? "asc" : "desc"}&sortBy=${
        activeOrder.orderParam
      }&`;
    return string;
  }

  function handleReset() {
    onChange({});
  }

  function handleSubmit() {
    onChange({
      filters: activeFilters,
      order: activeOrder,
      string: convertToString(),
    });
    handlePopoverClose();
  }

  // popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setActiveFilters([]);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // load current filter & order
  useEffect(() => {
    if (currentFilters.filters) setActiveFilters(currentFilters.filters);
    if (currentFilters.order) setActiveOrder(currentFilters.order);
    if (!currentFilters.filters && !currentFilters.order) {
      if (filters.filter && filters.filter.items) {
        var newActiveFilters = [];
        console.log("WORKS");
        filters.filter.items.forEach((filter) => {
          if (filter.active) newActiveFilters.push(filter);
        });
        setActiveFilters(newActiveFilters);
      }
      if (filters.order && filters.order.items.length > 0) {
        filters.order.items.forEach((order) => {
          if (order.active)
            setActiveOrder({ orderParam: order.value, orderDir: true });
        });
      }
    }
  }, [anchorEl]);

  return (
    <Box className="flex flex-row gap-1">
      <Tooltip title="Filter">
        {label ? (
          <Button className="flex flex-row gap-2 " onClick={handlePopoverOpen}>
            {label} {Icon && <Icon />}
          </Button>
        ) : (
          <IconButton onClick={handlePopoverOpen}>
            <Icon />
          </IconButton>
        )}
      </Tooltip>
      <Popover
        id="simple-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box className="flex flex-col p-4 gap-4 relative">
          {!noClear && (
            <IconButton
              onClick={handleReset}
              className="top-2 right-2"
              sx={{
                position: "absolute",
              }}
            >
              <FilterAltOffIcon />
            </IconButton>
          )}
          {filters.filter && (
            <FilterGroup
              activeFilters={activeFilters}
              groupKey={"filter"}
              group={filters.filter}
              onChange={handleFilterChange}
            />
          )}
          {filters.order && (
            <OrderGroup
              activeOrder={activeOrder}
              groupKey={"order"}
              group={filters.order}
              onChange={handleOrderChange}
            />
          )}
          <Button
            className="w-full"
            variant="contained"
            color="success"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

export default ExoAPIFilter;
