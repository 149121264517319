import { Box, Dialog, Typography, useTheme } from "@mui/material";
import WorkDayHour from "./WorkDayHour";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../global/theme/tokens";
import moment from "moment";
import ModifyTime from "./ModifyTime";
import { useTranslation } from "react-i18next";
import TimeDetailsViewer from "./time-components/TimeDetailsViewer";
import TimeSelectionBox from "./time-components/TimeSelectionBox";
import TimeBox from "./time-components/TimeBox";
import { timesOverlapping } from "./time-functions/overlappingTimes";
import { TimeElement } from "./time-components/TimeElement";

const WorkDayColumn = ({
  onDateClick,
  dayLabelHeight,
  index,
  edit,
  workdays,
  onUpdate = () => {},
  onAdd = () => {},
  onDelete = () => {},
  dayLabel,
  exoWorkdayRef,
  snapToGrid,
  snapRatio,
  hourStart,
  hourEnd,
  res,
  resHeight,
  columnDate = moment().startOf("day").format("YYYY-MM-DD"),
  workdayKey,
  setEnableTouchScroll,
  columnMoment,
  noInfo,
  userId,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  // size controll
  const boxRef = useRef(null);
  const [mobileView, setMobileView] = useState(false);
  const handleResize = () => {
    // Update containerWidth when the container is resized
    if (!boxRef.current) return;
    const width = boxRef.current.offsetWidth;
    setMobileView(width < 160);
  };

  useEffect(() => {
    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial setup
    handleResize();

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [boxRef, columnMoment]);

  // selection and boxes
  const selectionRef = useRef(null);
  const dragBox = useRef(null);
  const [selection, setSelection] = useState({
    timeStart: null,
    timeEnd: null,
  });
  const [mouseIsDown, setMouseIsDown] = useState(false);
  const [handlerType, setHandlerType] = useState("lower");

  const handleMouseDown = (e, type = "lower") => {
    if (!edit) return;
    setHandlerType(type);
    setMouseIsDown(true);
    e.preventDefault();
    if (selection.timeStart && selection.timeEnd) return;

    const mouseY = e.clientY + exoWorkdayRef.current.scrollTop;
    const dragBoxOffsetTop = dragBox.current.offsetTop;
    const startY =
      Math.round((mouseY - dragBoxOffsetTop) / resHeight) * resHeight;

    setSelection({
      timeStart: pixelToDate(startY),
      timeEnd: pixelToDate(startY),
    });
  };

  const handleMouseUp = (e) => {
    if (!edit) return;
    if (!mouseIsDown) return;
    setMouseIsDown(false);
    setHandlerType("upper");
    if (snapToGrid && selection.timeStart && selection.timeEnd)
      snapSelectionToGrid();
  };

  const handleMouseMove = (e) => {
    if (!edit) return;
    if (!mouseIsDown) return;
    const mouseY = e.clientY + exoWorkdayRef.current.scrollTop;
    const dragBoxOffsetTop = dragBox.current.offsetTop;
    var invert = selection.timeStart + "" > selection.timeEnd + "";

    if (handlerType === "lower") {
      setSelection((prevSelection) => {
        if (invert) {
          setHandlerType("upper");
          return {
            ...prevSelection,
            timeStart: pixelToDate(mouseY - dragBoxOffsetTop),
          };
        }
        return {
          ...prevSelection,
          timeEnd: pixelToDate(mouseY - dragBoxOffsetTop),
        };
      });
    }
    if (handlerType === "upper") {
      setSelection((prevSelection) => {
        if (invert) {
          setHandlerType("lower");
          return {
            ...prevSelection,
            timeEnd: pixelToDate(mouseY - dragBoxOffsetTop),
          };
        }
        return {
          ...prevSelection,
          timeStart: pixelToDate(mouseY - dragBoxOffsetTop),
        };
      });
    }
    if (handlerType === "move") {
      setSelection((prevSelection) => ({
        ...prevSelection,
        timeEnd: pixelToDate(dateToPixel(prevSelection.timeEnd) + e.movementY),
        timeStart: pixelToDate(
          dateToPixel(prevSelection.timeStart) + e.movementY
        ),
      }));
    }
  };

  const handleTouchStart = (e, type = "lower") => {
    if (!edit) return;
    setHandlerType(type);
    setMouseIsDown(true);
    setEnableTouchScroll(false);
    if (selection.timeStart && selection.timeEnd) return;

    const touchY = e.touches[0].clientY + exoWorkdayRef.current.scrollTop;
    const dragBoxOffsetTop = dragBox.current.offsetTop;
    const startY =
      Math.round((touchY - dragBoxOffsetTop) / resHeight) * resHeight;

    setSelection({
      timeStart: pixelToDate(startY),
      timeEnd: pixelToDate(startY),
    });
  };

  const handleTouchEnd = (e) => {
    if (!edit) return;
    if (!mouseIsDown) return;
    setEnableTouchScroll(true);
    setMouseIsDown(false);
    setHandlerType("upper");
    if (snapToGrid) snapSelectionToGrid();
  };

  const [prevTouchY, setprevTouchY] = useState(0);
  const handleTouchMove = (e) => {
    if (!edit) return;
    if (!mouseIsDown) return;
    const touchY = e.touches[0].clientY + exoWorkdayRef.current.scrollTop;
    var deltaY = 0;
    if (prevTouchY) {
      const height = selectionRef.current.offsetHeight;
      if (handlerType === "lower") {
        setprevTouchY(touchY - height / 2);
      }
      if (handlerType === "upper") {
        setprevTouchY(touchY + height / 2);
      }
      if (handlerType === "move") {
        setprevTouchY(touchY);
      }
      deltaY = touchY - prevTouchY;
    } else {
      setprevTouchY(touchY);
    }

    const dragBoxOffsetTop = dragBox.current.offsetTop;
    var invert = selection.timeStart + "" > selection.timeEnd + "";

    if (handlerType === "lower") {
      setSelection((prevSelection) => {
        if (invert) {
          setHandlerType("upper");
          return {
            ...prevSelection,
            timeStart: pixelToDate(touchY - dragBoxOffsetTop),
          };
        }
        return {
          ...prevSelection,
          timeEnd: pixelToDate(touchY - dragBoxOffsetTop),
        };
      });
    }
    if (handlerType === "upper") {
      setSelection((prevSelection) => {
        if (invert) {
          setHandlerType("lower");
          return {
            ...prevSelection,
            timeEnd: pixelToDate(touchY - dragBoxOffsetTop),
          };
        }
        return {
          ...prevSelection,
          timeStart: pixelToDate(touchY - dragBoxOffsetTop),
        };
      });
    }
    if (handlerType === "move") {
      setSelection((prevSelection) => ({
        ...prevSelection,
        timeEnd: pixelToDate(dateToPixel(prevSelection.timeEnd) + deltaY),
        timeStart: pixelToDate(dateToPixel(prevSelection.timeStart) + deltaY),
      }));
    }
  };

  function pixelToDate(pixel) {
    const numOfSegments = pixel / resHeight;
    const minutesPerSegment = 60 / res;
    const startMinutes = hourStart * 60;
    const minutes =
      numOfSegments * minutesPerSegment + startMinutes - minutesPerSegment;
    const dateTime = moment(columnDate).add(minutes, "minutes").toISOString();
    dateToPixel(dateTime);
    return dateTime;
  }

  function dateToPixel(date) {
    const minutesPerSegment = 60 / res;

    const startDate = moment(date);
    var minutes =
      startDate.hours() * 60 + startDate.minutes() + startDate.seconds() / 60;

    const startMinutes = hourStart * 60;
    // limit the upper end
    if (startMinutes >= minutes) minutes = startMinutes;

    const endMinutes = hourEnd * 60;
    // limit the lower end
    if (endMinutes <= minutes) minutes = endMinutes;

    const numOfSegments = (minutes - startMinutes) / minutesPerSegment;
    const pixel = numOfSegments * resHeight + resHeight;
    return pixel;
  }

  function snapSelectionToGrid() {
    if (timesOverlapping(workdays[workdayKey], selection))
      return snapToOverlappingSection(
        timesOverlapping(workdays[workdayKey], selection)
      );
    var pixelEnd = dateToPixel(selection.timeEnd);
    const pixelStart = dateToPixel(selection.timeStart);
    if (pixelEnd - pixelStart < resHeight) pixelEnd += resHeight;

    const snappedDateStart = pixelToDate(snapPixel(pixelStart));
    const snappedDateEnd = pixelToDate(snapPixel(pixelEnd));
    if (
      timesOverlapping(workdays[workdayKey], {
        ...selection,
        timeStart: snappedDateStart,
        timeEnd: snappedDateEnd,
      })
    )
      return snapToOverlappingSection(
        timesOverlapping(workdays[workdayKey], {
          ...selection,
          timeStart: snappedDateStart,
          timeEnd: snappedDateEnd,
        })
      );
    setSelection({
      ...selection,
      timeStart: snappedDateStart,
      timeEnd: snappedDateEnd,
    });
  }

  function snapToOverlappingSection({ time1, time2 }) {
    // time2 is the selection
    var selectedTime = time2;
    if (
      selectedTime.timeStart < time1.timeStart &&
      selectedTime.timeEnd > time1.timeStart
    )
      selectedTime.timeEnd = time1.timeStart;

    if (
      selectedTime.timeStart < time1.timeEnd &&
      selectedTime.timeEnd > time1.timeEnd
    )
      selectedTime.timeStart = time1.timeEnd;

    if (
      selectedTime.timeStart >= time1.timeStart &&
      selectedTime.timeEnd <= time1.timeEnd
    ) {
      setSelection({
        timeStart: null,
        timeEnd: null,
      });
    }
  }

  function snapPixel(pixel) {
    const snappedPixels =
      Math.round(pixel / (resHeight / snapRatio)) * (resHeight / snapRatio);
    return snappedPixels;
  }

  // modification
  const [open, setOpen] = useState(false);
  function handleSubmit() {
    setOpen(!timesOverlapping(workdays[workdayKey], selection));
  }

  function handleAdd(data) {
    if (timesOverlapping(workdays[workdayKey], selection)) return;
    onAdd(data, workdayKey);
    setSelection({});
    setOpen(false);
  }
  function handleUpdate(data) {
    if (timesOverlapping(workdays[workdayKey], selection)) return;
    onUpdate(data, workdayKey);
    setSelection({});
    setOpen(false);
  }

  function handleDelete() {
    if (selection.id) {
      onDelete({ ...selection, method: "delete" }, workdayKey);
      setSelection({});
    }
  }

  function handleCancle() {
    setSelection({});
  }
  const [openInfoBox, setOpenInfoBox] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  function handleSelect(time) {
    if (edit) {
      setSelection(time);
      return;
    }
    if (!edit && !noInfo) {
      setOpenInfoBox(true);
      setSelectedTime(time);
    }
  }

  function handleEdit() {
    setOpen(true);
  }

  // check if its today
  const today =
    columnMoment.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD");
  return (
    <Box className="w-full " ref={boxRef}>
      <Box
        className="w-full sticky top-0 left-0 z-10 px-1 flex"
        sx={{
          height: `${dayLabelHeight}px`,
        }}
      >
        <Box
          onClick={onDateClick ? () => onDateClick(columnMoment) : () => {}}
          className="rounded-lg w-full h-full transition-colors backdrop-blur-lg px-1 flex flex-col justify-center items-center overflow-hidden"
          sx={{
            cursor: onDateClick ? "pointer" : "default",

            bgcolor: today ? colors.card : "transparent",
            ":hover": {
              bgcolor: onDateClick ? colors.card : "transparent",
            },
          }}
        >
          <Typography
            variant="h3"
            textAlign="center"
            className=" whitespace-nowrap h-full w-full flex justify-center items-center rounded-lg pt-1"
          >
            {mobileView ? t(dayLabel).charAt(0) : t(dayLabel)}
          </Typography>
          <Typography
            variant="h6"
            textAlign="center"
            className=" whitespace-nowrap h-full w-full flex justify-center items-center rounded-lg"
          >
            {columnMoment.format("DD.MM") === "Invalid date"
              ? ""
              : columnMoment.format("DD.MM")}
          </Typography>
        </Box>
      </Box>
      <Box
        className="block relative w-full"
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        ref={dragBox}
      >
        {[...Array(hourEnd - hourStart)].map((e, i) => (
          <Box
            className="flex flex-row w-full"
            key={i}
            onMouseDown={selection.timeStart ? () => {} : handleMouseDown}
            onTouchStart={selection.timeStart ? () => {} : handleTouchStart}
          >
            <WorkDayHour
              hour={i + hourStart}
              hourEnd={hourEnd}
              res={res}
              resHeight={resHeight}
              noHours={Boolean(index === 0)}
            />
          </Box>
        ))}
        {workdays[workdayKey] &&
          workdays[workdayKey].map((time, index) => (
            <TimeBox
              mobileView={mobileView}
              key={index}
              time={time}
              marginLeft={0}
              dateToPixel={dateToPixel}
              onClick={handleSelect}
              selected={Boolean(selection.id === time.id)}
              edit={edit}
            />
          ))}
        {false &&
          workdays[workdayKey] &&
          workdays[workdayKey].map((time, index) => (
            <TimeElement
              key={time.id}
              time={time}
              selectionRef={selectionRef}
              dateToPixel={dateToPixel}
              mouseIsDown={mouseIsDown}
              selection={selection}
              marginLeft={0}
              hourStart={hourStart}
              onMouseDown={handleMouseDown}
              onTouchStart={handleTouchStart}
              onCancle={handleCancle}
              onSubmit={handleSubmit}
              onEdit={handleEdit}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              snapSelectionToGrid={snapSelectionToGrid}
            />
          ))}
        {selection.timeStart && selection.timeEnd && (
          <TimeSelectionBox
            selectionRef={selectionRef}
            dateToPixel={dateToPixel}
            mouseIsDown={mouseIsDown}
            selection={selection}
            marginLeft={0}
            hourStart={hourStart}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            onCancle={handleCancle}
            onSubmit={handleSubmit}
            onEdit={handleEdit}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            snapSelectionToGrid={snapSelectionToGrid}
          />
        )}
      </Box>
      <Dialog open={open}>
        <ModifyTime
          userId={userId}
          time={selection}
          onChange={setSelection}
          onSubmit={handleAdd}
          onCancle={() => {
            setOpen(false);
            handleCancle();
          }}
        />
      </Dialog>
      <TimeDetailsViewer
        open={openInfoBox}
        time={selectedTime}
        onClose={() => setOpenInfoBox(false)}
      />
    </Box>
  );
};

export default WorkDayColumn;
