import { Box } from "@mui/material";
import { useState } from "react";
import { TimeClock } from "./time-overview/TimeClock";
import { TimeElement } from "./time-overview/TimeElement";

export const WorkDayOverview = ({ workdays, userId }) => {
  const keys = Object.keys(workdays);

  const [currentKey, setCurrentKey] = useState(keys[0]);

  return (
    <Box
      className="h-full w-80 hidden md:flex flex-col overflow-hidden"
      sx={{ minWidth: "300px" }}
    >
      <Box
        className="w-40 overflow-hidden relative mx-auto"
        sx={{ minHeight: "160px" }}
      >
        <TimeClock
          currentKey={currentKey}
          workdays={workdays}
          userId={userId}
        />
      </Box>

      <Box className="flex h-full flex-col gap-2 pt-6 overflow-y-auto">
        {workdays[currentKey] &&
          workdays[currentKey].map((time) => (
            <TimeElement key={time.id} time={time} />
          ))}
      </Box>
    </Box>
  );
};
