import React from "react";
import ExoTable from "../exo/ExoTable";
import { Box, Button } from "@mui/material";
import MoneyLabel from "../label/MoneyLabel";
import { useTranslation } from "react-i18next";
import useFetch from "../../api/useFetch";
import DateLabel from "../label/DateLabel";
import { ExoTableUser } from "../exo/ExoTableUser";

export const SelectPayroll = ({ filter, onSelect, className }) => {
  const { t } = useTranslation();

  const { apiData } = useFetch(`payrolls?${filter}`);

  const Columns = [
    {
      header: t("Employee"), // header of column
      key: "user", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      cell: ({ row }) => <ExoTableUser user={row.user} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Monat"), // header of column
      key: "endDate", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "230px", // width of column
      hiddenMobile: true,
      cell: ({ row }) => (
        <Box className="monospace">
          <DateLabel format="MM/YYYY" date={row.endDate} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Salary"), // header of column
      key: "startDate", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "230px", // width of column
      hiddenMobile: true,
      cell: ({ row }) => (
        <Box className="monospace">
          <MoneyLabel money={row.amount} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
    {
      header: "", // header of column
      key: "file", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "100px", // width of column
      cell: ({ row }) => (
        <Button onClick={() => onSelect(row)} variant="contained">
          {t("Select")}
        </Button>
      ), // custom xml for cells in the column. you get the row as object
    },
  ];
  return (
    <ExoTable
      data={apiData}
      columns={Columns}
      isLoading={!apiData}
      className={className}
    />
  );
};
