import { useTheme, Box, Skeleton, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import AddIcon from "@mui/icons-material/Add";
import PersonRadio from "../radio/PersonRadio";
import NewContact from "../new/NewContact";
import { useTranslation } from "react-i18next";
import DBSearch from "../special/DBSearch";
import ExoProcessing from "../exo/ExoProcessing";

const SelectContact = ({ onSelect, filter = "", preSelected, name }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const config = useConfig();
  const { t } = useTranslation();

  // request
  const [searchResults, setSearchResults] = useState(null);

  // aktions
  const [selectedId, setSelectedId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  function handleSearchResultSelect(element) {
    DBRequest({
      config,
      path: `contacts/${element.id}`,
      onResponse: (res) => {
        setSelectedId(res.data.id);
        onSelect(res.data);
      },
      onLoading: setIsProcessing,
    });
  }

  useEffect(() => {
    if (preSelected) setSelectedId(preSelected.id || null);
  }, [preSelected]);

  // new contact
  const [openNewContact, setOpenNewContact] = useState(false);
  function handleNewContact(data) {
    var newElements = searchResults;
    const newHit = {
      id: data.data.id,
      label: `${data.data.firstName} ${data.data.lastName}`,
      subLable: data.data.position,
      image: "",
    };
    newElements.unshift(newHit);
    setSearchResults(newElements);
  }

  return (
    <Box className=" flex flex-col gap-2 h-full overflow-y-auto pr-2">
      <Box className="sticky top-0 z-10">
        <DBSearch param={"contacts"} onSearch={setSearchResults} />
      </Box>
      {!searchResults ? (
        <>
          {[...Array(8)].map((element, index) => (
            <Skeleton key={"skeleton-select-element-" + index} height={64} />
          ))}
        </>
      ) : (
        <>
          <PersonRadio
            person={{
              logo: {},
              icon: <AddIcon />,
              id: 0,
              firstName: t("New Contact"),
              lastName: "",
            }}
            radioKey="contactRadio"
            key={name + "select-contact-" + 0}
            onClick={() => {
              setOpenNewContact(true);
            }}
          />

          {searchResults.map((element, index) => (
            <PersonRadio
              person={{
                logo: {},
                id: element.id,
                firstName: element.label,
                lastName: "",
                position: element.subLable,
              }}
              key={name + "select-contact-" + element.id}
              onClick={(e) => handleSearchResultSelect(element)}
              checked={selectedId === element.id ? true : false}
            />
          ))}

          <Dialog
            onClose={() => setOpenNewContact(false)}
            open={openNewContact}
          >
            <NewContact
              onClose={() => setOpenNewContact(false)}
              onCreate={handleNewContact}
            />
          </Dialog>
        </>
      )}

      <ExoProcessing open={isProcessing} label={t("Loading Contact")} />
    </Box>
  );
};

export default SelectContact;
