import {
  Backdrop,
  Box,
  SpeedDial,
  SpeedDialAction,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { tokens } from "../../global/theme/tokens";

const defaultActions = [
  { icon: <FileCopyIcon />, name: "Copy", onClick: () => console.log("Copy") },
  { icon: <SaveIcon />, name: "Save", onClick: () => console.log("Save") },
  { icon: <PrintIcon />, name: "Print", onClick: () => console.log("Print") },
  { icon: <ShareIcon />, name: "Share", onClick: () => console.log("Share") },
];

const SpeedDialMenu = ({
  actions = defaultActions,
  direction = "down",
  className = "top-1 right-1 absolute",
  margin = "0 0 0 0 ",
  noBlur,
  Icon = MoreVertIcon,
  backgroundColor,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {!noBlur && (
        <Backdrop open={open} onClick={handleClose} sx={{ zIndex: 777 }} />
      )}

      <Box
        className={className}
        sx={{
          width: "36px",
          height: "36px",
          "& button": {
            margin: "0",
          },
        }}
      >
        <SpeedDial
          className={className}
          ariaLabel="SpeedDial tooltip"
          sx={{
            "& .MuiSpeedDial-actions": {
              gap: "5px",
            },
            ">Button": {
              backgroundColor: open
                ? colors.card
                : backgroundColor || colors.glass,
              height: "36px",
              width: "36px",
              margin: margin,
              boxShadow: !open && "none",
            },
          }}
          icon={<Icon />}
          onClick={() => setOpen(!open)}
          open={open}
          direction={direction}
        >
          {actions.map((action, index) => {
            if (action.hidden) return;

            return (
              <SpeedDialAction
                key={index}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={() => {
                  action.onClick();
                  handleClose();
                }}
              />
            );
          })}
        </SpeedDial>
      </Box>
    </>
  );
};

export default SpeedDialMenu;
