import React, { createElement, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import Entry from "../position-modules/Entry";
import { stringToNumber } from "../../special/numberConverter";
import { Box, Collapse, IconButton, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../../global/theme/tokens";

export const PositionsRow = ({
  onChange,
  onFocus,
  item,
  items,
  index,
  columns,
  showVars,
  descriptionVars,
  imageType,
  imageApiFilter,
  collapseModules,
  openAmount,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const [expand, setExpand] = useState(false);

  // change
  function handleChange(e, col) {
    var newItems = items;
    if (col.number) {
      newItems[index][col.key] = stringToNumber(
        e.target.value,
        col.decimalPlaces ? col.decimalPlaces : 2
      );
    } else {
      newItems[index][col.key] = e.target.value;
    }
    onChange([...newItems]);
  }

  function handleDefaultChange(value, key) {
    var newItems = items;
    newItems[index][key] = value;
    onChange([...newItems]);
  }

  const handleDragStart = () => {
    setExpand(false); // Collapse when dragging starts
  };

  return (
    <Draggable draggableId={"item-" + item.id + "-" + index} index={index}>
      {(provided, snapshot) => (
        <>
          <tr
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            onDragStart={handleDragStart}
            className={item.orderId ? "order-position" : ""}
          >
            {collapseModules && (
              <td>
                <IconButton onClick={() => setExpand(!expand)}>
                  <ExpandMoreIcon
                    sx={{
                      transform: `rotate(${expand ? "-180deg" : "0deg"})`,
                      transition: "0.2s",
                    }}
                  />
                </IconButton>
              </td>
            )}
            {columns.map((col, i) => (
              <Entry
                key={item.position + i}
                imageType={imageType}
                imageApiFilter={imageApiFilter}
                col={col}
                item={items[index]}
                onFocus={(ref) => onFocus(ref, col.key)}
                onChange={(e) => handleChange(e, col)}
              >
                {item[col.key]}
              </Entry>
            ))}
          </tr>
          {!snapshot.isDragging && collapseModules && (
            <tr>
              <td colSpan={999}>
                <Collapse in={expand} className="px-6">
                  <Box
                    className="w-full grid gap-4 p-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 rounded-lg mb-3 mt-1"
                    sx={{
                      borderColor: colors.selected,
                      borderWidth: "1px",
                    }}
                  >
                    {collapseModules.map((module) =>
                      createElement(module.component, {
                        openAmount,
                        imageType,
                        imageApiFilter,
                        position: item,
                        data: item[module.key],
                        moduleKey: module.key,
                        onFocus: (ref) => onFocus(ref, module.key),
                        unit: module.unit,
                        label: module.label,
                        defaultValue: module.value,
                        decimalPlaces: module.decimalPlaces,
                        descriptionVars,
                        showVars,
                        onChange: (value, manuelKey) =>
                          handleDefaultChange(value, manuelKey || module.key),
                      })
                    )}
                  </Box>
                </Collapse>
              </td>
            </tr>
          )}
        </>
      )}
    </Draggable>
  );
};
