import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import TimeHolder from "./TimeHolder";
import { renderHours } from "../../../../scenes/time-tracking/worktime";
import EditIcon from "@mui/icons-material/Edit";

export const TimeElement = ({
  marginLeft,
  onDelete,
  onChange,
  onEdit,
  time,
  dateToPixel,
  pixelToDate,
  selectionRef,
  selection,
  onMouseDown,
  onTouchStart,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const padding = 10;

  // time
  const startMoment = moment(time.timeStart);
  const endMoment = moment(time.timeEnd);
  const timeInMinutes = endMoment.diff(startMoment, "minutes");
  const timeStart = startMoment.hours() * 60 + startMoment.minutes();

  // position
  const [position, setPosition] = useState();

  function calcPosition() {
    const top = dateToPixel(time.timeStart);
    const height = Math.abs(dateToPixel(time.timeEnd) - top);

    setPosition({ height, top });
  }

  useEffect(() => {
    calcPosition();
  }, []);

  if (!position) return;
  return (
    <Box
      className="absolute flex px-2 w-full"
      style={{
        top: `${position.top}px`,
        height: `${position.height}px`,
        width: "100%",
        zIndex: 1,
        paddingLeft: `${marginLeft + padding}px`,
        paddingRight: `${padding}px`,
      }}
    >
      <Box
        ref={selectionRef}
        className="relative h-full w-full rounded-lg flex justify-center items-center flex-col backdrop-blur-sm"
        sx={{
          bgcolor: `${colors.blueAccent[400]}80`,
          borderColor: `${colors.blueAccent[400]}90`,
          borderWidth: `1px`,
          ".timeHandle-bottom, .timeHandle-top": {
            transition: "0.4s ease",
            opacity: 0,
          },
          ".timeHandle-top": {
            transform: "translate(-50%, 50%)",
          },
          ".timeHandle-bottom": {
            transform: "translate(-50%, -50%)",
          },
          ".totalTime": {
            transition: "0.4s ease",
            transform: `translateY(${position.height / 2 - 13}px)`,
          },
          ":hover": {
            ".timeHandle-bottom, .timeHandle-top": {
              opacity: 1,
            },
            ".timeHandle-top": {
              transform: "translate(-50%, -50%)",
            },
            ".timeHandle-bottom": {
              transform: "translate(-50%, 50%)",
            },
            ".totalTime": {
              position: "relative",
              transform: `translateY(0px)`,
            },
          },
        }}
      >
        <TimeHolder
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          type="upper"
          timeInMinutes={timeStart}
          sx={{
            bgcolor: `${colors.glass}`,
          }}
          className=" backdrop-blur-sm left-1/2 timeHandle-top absolute top-0 cursor-grab rounded-full px-2 py-1"
        />

        <Box
          className="px-3 py-2 rounded-lg cursor-move z-10  monospace totalTime "
          sx={{
            opacity: position.height >= 80 ? 1 : 0,
            transition: "opacity 0.3s",
          }}
          onMouseDown={(e) => {
            onMouseDown(e, "move");
          }}
          onTouchStart={(e) => {
            onTouchStart(e, "move");
          }}
        >
          {renderHours(timeInMinutes)} h
        </Box>

        <TimeHolder
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          type="lower"
          timeInMinutes={timeStart + timeInMinutes}
          sx={{
            bgcolor: `${colors.glass}`,
          }}
          className="backdrop-blur-sm left-1/2 absolute bottom-0 timeHandle-bottom cursor-grab rounded-full px-2 py-1"
        />
      </Box>
    </Box>
  );
};
