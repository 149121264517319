import { Box, Button, Typography } from "@mui/material";
import CardGrid from "../../../components/cards/layout/CardGrid";
import {
  requestTypesIcons,
  requestTypesStrings,
} from "../../../components/special/requestTypes";
import CardContainer from "../../../components/cards/layout/CardContainer";
import PictureNameCard from "../../../components/cards/PictureNameCard";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../global/theme/tokens";
import { API_GET, API_PATCH, DBRequest, useConfig } from "../../../api/api";
import Feedback from "../../../components/special/Feedback";
import { useEffect, useState } from "react";
import ExoWorkday from "../../../components/exo/ExoWorkday";
import DateTimeLabel from "../../../components/label/DateTimeLabel";
import { useTranslation } from "react-i18next";
import SuccessCheck from "../../../components/special/SuccessCheck";
import FailureCross from "../../../components/special/FailureCross";

const ExtendTimeCorrection = ({ request, onUpdate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const config = useConfig();
  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  const [showRequest, setShowRequest] = useState(null);
  useEffect(() => {
    DBRequest({
      config,
      path: `employeerequests/${request.id}`,
      method: API_GET,
      onResponse: setShowRequest,
    });
  }, [request, config]);
  return (
    <>
      <CardGrid>
        <CardContainer span={5} className="">
          <Typography
            variant="h3"
            className="flex items-center justify-center p-2 gap-2"
          >
            {requestTypesIcons[request.type]}
            {`${requestTypesStrings[request.type]} - `}
            <DateTimeLabel dateTime={request.createdAt} />
          </Typography>
        </CardContainer>
        <CardContainer span={3} rows={2}>
          <Box className="flex w-full justify-between">
            {showRequest && (
              <ExoWorkday
                res={2}
                userId={request.user.id}
                resHeight={15}
                corrections={showRequest.data.corrections}
                customColumnLabel
                noInfo
              />
            )}
          </Box>
        </CardContainer>
        <PictureNameCard
          title="Requester"
          pictureUrl={
            request.user.picture ? request.user.picture.temporaryUrl : ""
          }
          name={request.user.firstName + " " + request.user.lastName}
          mail={request.user.email}
          phone={request.user.phone}
          position={request.user.position}
        />

        <CardContainer>
          <RequestController
            request={request}
            onUpdate={onUpdate}
            onFeedback={handleAlertOpen}
          />
        </CardContainer>
      </CardGrid>

      <Feedback setState={setAlertState} state={alertState} />
    </>
  );
};

const RequestController = ({ request, onUpdate, onFeedback }) => {
  const config = useConfig();
  const { t } = useTranslation();
  function handleStatusUpdate(value) {
    const data = { status: value };

    DBRequest({
      config,
      path: `employeerequests/${request.id}`,
      method: API_PATCH(data),
      onResponse: handleUpdate,

      onFeedback,
    });
  }

  function handleUpdate(response) {
    onUpdate(response.data);
  }

  return (
    <Box className="flex flex-col gap-2">
      {request.status !== 1 && (
        <>
          {request.status !== 0 && <FailureCross />}
          <Button
            color="success"
            variant="contained"
            onClick={() => handleStatusUpdate(1)}
          >
            {t("Accept")}
          </Button>
        </>
      )}
      {request.status !== 2 && (
        <>
          {request.status !== 0 && <SuccessCheck />}
          <Button
            color="error"
            variant="contained"
            onClick={() => handleStatusUpdate(2)}
          >
            {t("Decline")}
          </Button>
        </>
      )}
    </Box>
  );
};

export default ExtendTimeCorrection;
