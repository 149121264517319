import { Box, useTheme, Typography, Button } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import ExoAvatar from "../../../components/exo/ExoAvatar";
import { NavLink } from "react-router-dom";
export const ProjectGridItem = ({ project }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <NavLink to={`/projects/management/${project.id}`}>
      <Button
        variant="contained"
        color="secondary"
        sx={{
          transition: "scale 0.3s ease",
        }}
        className=" cursor-pointer w-full flex flex-col backdrop-blur-lg items-center p-4 rounded-lg"
      >
        {project.image ? (
          <img
            src={project.image.temporaryUrl}
            className="w-full h-32 object-contain"
          />
        ) : (
          <ExoAvatar
            picture={project.client.logo && project.client.logo.temporaryUrl}
            type={project.clientType}
            name={
              project.clientType === "company"
                ? project.client.nameShort
                : `${project.client.lastName} ${project.client.firstName}`
            }
            size="128px"
          />
        )}

        <Typography variant="h3" className="pt-4">
          <b>{project.nr}</b>
        </Typography>
        <Typography variant="h6">{project.name}</Typography>
      </Button>
    </NavLink>
  );
};
