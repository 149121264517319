import { Box, Typography } from "@mui/material";

export const CardContentInfo = ({ className, Icon, children, title }) => {
  return (
    <Box className="flex flex-col gap-2 justify-start items-center ">
      {Icon && <Icon />}
      <Box className="flex flex-col justify-center items-center">
        <Typography variant="h4" className={className}>
          {children}
        </Typography>
        <Typography variant="p" sx={{ opacity: "0.5" }}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
