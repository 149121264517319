import { Box, IconButton, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MediaPreview from "./MediaPreview";

const MediaGridItem = ({ selected, onClick, item, onRemove }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      onClick={onClick ? () => onClick(item) : () => {}}
      sx={{
        backgroundColor: colors.glass,
        transition: "0.4s",
        borderWidth: "2px",
        borderColor: selected ? colors.selected : colors.glass,
        ":hover": {
          borderColor: selected ? colors.selected : colors.selected,
        },
      }}
      className="relative rounded-xl  w-full hover:cursor-pointer aspect-square flex justify-center items-center overflow-hidden"
    >
      {onRemove && (
        <IconButton
          className="top-0 right-0"
          sx={{ position: "absolute" }}
          color="error"
          onClick={() => onRemove(item)}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      )}
      <MediaPreview
        type={item.type}
        src={item.thumbnailUrl}
        mimeType={item.mimeType}
        size="100%"
      />
    </Box>
  );
};

export default MediaGridItem;
