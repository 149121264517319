import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import LabeledBox from "../../special/LabeledBox";
import { useEffect, useState } from "react";
import ExoDialog from "../../exo/ExoDialog";
import { useTranslation } from "react-i18next";
import ExoTabs from "../../exo/ExoTabs";
import ContactsIcon from "@mui/icons-material/Contacts";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import SelectCompanies from "../../select/SelectCompanies";
import SelectContacts from "../../select/SelectContacts";
import SelectEmployees from "../../select/SelectEmployees";
import { SelectFromNameValueArray } from "../../select/SelectFromNameValueArray";
import Feedback from "../../special/Feedback";

const TypeMailSelect = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState([]);

  function handleSelectionChange(value) {
    updateValidationOnChange([field.key], value);
    setSelection(value);
  }

  useEffect(() => {
    console.log(dataCollection[field.key]);

    if (selection && !selection.length && dataCollection[field.key]) {
    }
    //setSelection(dataCollection[field.key]);
  }, [selection]);

  return (
    <>
      <LabeledBox
        className="col-span-2"
        onClick={() => setOpen(true)}
        label={field.label}
      >
        {selection.length === 0 && t("No CC Selected")}
        {selection.map((item, index) => (
          <MailItem item={item} />
        ))}
      </LabeledBox>
      <ExoDialog open={open}>
        <SelectMail
          label={field.label}
          onCancle={() => setOpen(false)}
          onSave={handleSelectionChange}
          startData={selection}
        />
      </ExoDialog>
    </>
  );
};

const MailItem = ({ item, onDelete = () => {} }) => {
  return (
    <Typography onClick={() => onDelete(item)}>
      <b>{item.name}</b> {`<${item.email}>`}
    </Typography>
  );
};

const SelectMail = ({ label, onCancle, onSave, startData }) => {
  const { t } = useTranslation();
  const [deliveryCCData, setDeliveryCCData] = useState([]);
  const [nameValueData, setNameValueData] = useState({});
  const [elementSave, setElementSave] = useState(null);

  const [selection, setSelection] = useState({
    company: [],
    contact: [],
    employee: [],
  });
  const [preSelected, setPreSelected] = useState({
    company: [],
    contact: [],
    employee: [],
  });
  useEffect(() => {
    setSelection({
      company: startData.filter((obj) => obj.type === "company"),
      contact: startData.filter((obj) => obj.type === "contact"),
      employee: startData.filter((obj) => obj.type === "employee"),
    });
  }, [startData]);

  const tabs = [
    {
      label: t("Companies"),
      content: (
        <SelectCompanies
          onSelect={handleCompanysSelect}
          dataCollection={selection.company}
        />
      ),
      icon: <ApartmentIcon />,
    },
    {
      label: t("Contacts"),
      content: (
        <SelectContacts
          onSelect={handleContactsSelect}
          dataCollection={selection.contact}
        />
      ),
      icon: <ContactsIcon />,
    },
    {
      label: t("Employees"),
      content: (
        <SelectEmployees
          onSelect={handleEmployeesSelect}
          preSelected={preSelected.employee}
        />
      ),
      icon: <PeopleOutlinedIcon />,
    },
  ];

  function handleCompanysSelect(data, element) {
    if (element && !element.emails.length) {
      handleFeedback(t("No E-Mail address found"), "error");
      return setSelection({ ...selection, company: [...selection.company] });
    }
    if (element) {
      setElementSave({ element, type: "company" });
      setNameValueData({
        array: element.emails,
        nameKey: "emailType",
        valueKey: "emailAddress",
      });
    } else {
      setSelection({
        ...selection,
        company: removeItemFromArray(selection.company, data),
      });
    }
  }

  function handleContactsSelect(data, element) {
    if (element && !element.emails.length) {
      handleFeedback(t("No E-Mail address found"), "error");
      return setSelection({ ...selection, contact: [...selection.contact] });
    }
    if (element) {
      setElementSave({ element, type: "contact" });
      setNameValueData({
        array: element.emails,
        nameKey: "emailType",
        valueKey: "emailAddress",
      });
    } else {
      setSelection({
        ...selection,
        contact: removeItemFromArray(selection.contact, data),
      });
    }
  }
  function handleEmployeesSelect(data) {
    setSelection({ ...selection, employee: data });
  }

  function handleEmailSelect(email) {
    var newArray = selection[elementSave.type];
    newArray.push({ ...elementSave.element, email });

    setSelection({
      ...selection,
      [elementSave.type]: newArray,
    });
    setNameValueData({});
  }

  function removeItemFromArray(arrA, arrB) {
    return arrA.filter((itemA) => arrB.some((itemB) => itemA.id === itemB.id));
  }

  useEffect(() => {
    generateCCDeliveryData();
  }, [selection]);

  function generateCCDeliveryData() {
    var newDeliveryCCData = [];
    selection.company.forEach((element) => {
      newDeliveryCCData.push({
        email: element.email,
        name: element.name,
        type: "company",
        id: element.id,
      });
    });
    selection.contact.forEach((element) => {
      newDeliveryCCData.push({
        email: element.email,
        name: `${element.salutation} ${element.firstName} ${element.lastName}`,
        type: "contact",
        id: element.id,
      });
    });
    selection.employee.forEach((element) => {
      newDeliveryCCData.push({
        email: element.email,
        name: `${element.salutation} ${element.firstName} ${element.lastName}`,
        type: "employee",
        id: element.id,
      });
    });
    setDeliveryCCData(newDeliveryCCData);
  }

  function handleSave() {
    onSave(deliveryCCData);
    onCancle();
  }

  function handleDelete(item) {
    const newSelection = [...selection[item.type]];
    const index = newSelection.findIndex((obj) => obj.id === item.id);
    if (index >= 0) {
      newSelection.splice(index, 1);
    }
    setSelection({ ...selection, [item.type]: newSelection });
    setPreSelected({ ...selection, [item.type]: newSelection });
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });
  function handleFeedback(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }
  return (
    <Box className="flex flex-col h-full justify-between gap-2 overflow-hidden">
      <Box className="flex flex-col h-full gap-2 overflow-hidden pt-2">
        <LabeledBox label={label}>
          <Box className="overflow-y-auto mb-4" sx={{ maxHeight: "100px" }}>
            {deliveryCCData.length === 0 && t("No Mails Selected")}
            {deliveryCCData.map((item, index) => (
              <MailItem item={item} onDelete={handleDelete} />
            ))}
          </Box>
        </LabeledBox>
        <ExoTabs tabs={tabs} />
      </Box>
      <Box className="flex justify-between p-2">
        <Button variant="outlined" color="error" onClick={onCancle}>
          {t("Cancle")}
        </Button>
        <Button variant="contained" color="success" onClick={handleSave}>
          {t("Save")}
        </Button>
      </Box>
      <ExoDialog open={Boolean(nameValueData.array)} limitWidth>
        <SelectFromNameValueArray
          label="Select email"
          array={nameValueData.array}
          nameKey={nameValueData.nameKey}
          valueKey={nameValueData.valueKey}
          onSelect={handleEmailSelect}
        />
      </ExoDialog>
      <Feedback state={alertState} setState={setAlertState} />
    </Box>
  );
};

export default TypeMailSelect;
