import { useEffect, useState } from "react";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../api/api";
import ExoStepper, { FinalPage } from "../exo/ExoStepper";
import SelectPositions from "../select/SelectPositions";
import StepAdditionalPositions from "../steps/StepAdditionalPositions";
import StepDelivery from "../steps/StepDelivery";
import StepCustomStartUp from "../steps/StepCustomStartUp";
import * as Yup from "yup";
import StepOrder from "../steps/StepOrder";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useFetch from "../../api/useFetch";
import StepUser from "../steps/StepUser";
import StepRecipient from "../steps/StepRecipient";
import { StepTemplate } from "../steps/StepTemplate";

const NewCustomDeliveryNote = ({
  onClose,
  startData,
  onUpdate = () => {},
  deliveryNotes,
  setDeliveryNotes,
}) => {
  const { t } = useTranslation();
  const config = useConfig();

  const fields = [
    {
      label: t("Title"),
      type: "text",
      key: "title",
    },
    {
      label: t("Delivery Date"),
      type: "date",
      key: "deliveryDate",
    },

    {
      label: t("Note"),
      type: "description",
      key: "note",
      multiline: true,
    },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("Title is required")),
    deliveryDate: Yup.string().required(t("Delivery Date is required")),
  });

  const steps = [
    {
      label: t("Recipient"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepRecipient
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t("Template"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepTemplate
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          templateType="delivery_note"
        />
      ),
    },
    {
      label: t("Info"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={fields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={validationSchema}
        />
      ),
    },
    {
      label: t("Custom Positions"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepAdditionalPositions
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          imageType={`ORDER_ATTACHMENT`}
          imageApiFilter={
            startData && startData.id
              ? `orderImages[has]=${startData.id}`
              : `orderImages[doesnothave]=0`
          }
        />
      ),
    },
    {
      label: t("Contact Person"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepUser
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
  ];

  const finalStep = {
    operation: ({
      dataCollection,
      handleResponse,
      setIsLoading,
      handleError,
    }) => {
      setIsLoading(true);

      const isUpdate = Boolean(dataCollection.id);

      DBRequest({
        config,
        path: isUpdate
          ? `delivery-notes/${dataCollection.id}`
          : "delivery-notes",
        method: isUpdate ? API_PATCH(dataCollection) : API_POST(dataCollection),
        onResponse: handleRes,
        onLoading: setIsLoading,
        onError: handleError,
      });

      function handleRes(data) {
        handleResponse(data);
        onUpdate(data);
      }
    }, // your operation with the data
    content: (response) => (
      <FinalPage
        response={response}
        data={deliveryNotes}
        setData={setDeliveryNotes}
      />
    ), // component with final operations and the feedback
  };

  const [defaultData, setDefaultData] = useState({});

  useEffect(() => {
    const newDefaultData = {
      deliveryDate: moment().format(),
      sourceType: "custom",
    };
    setDefaultData(newDefaultData);
  }, []);

  return (
    <ExoStepper
      startDataCollection={startData || defaultData}
      onClose={onClose}
      data={steps}
      finalStep={finalStep}
      message={t("Creating Delivery Note")}
      label={t("New Order Based Delivery Note")}
      previewDocument={"delivery_note"}
    />
  );
};

export default NewCustomDeliveryNote;
