import { Box, useTheme, Typography } from "@mui/material";
import moment from "moment";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { tokens } from "../../../../global/theme/tokens";
import { renderHours } from "../../../../scenes/time-tracking/worktime";
import { useTimeTracking } from "../../../time-tracking/AppTimeProvider";
import Cookies from "universal-cookie";
import { DBRequest, useConfig } from "../../../../api/api";
import { useEffect, useState } from "react";

export const TimeClock = ({ workdays, currentKey, userId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const config = useConfig();
  const { secondsIn } = useTimeTracking();

  const cookies = new Cookies();

  const [timeToday, setTimeToday] = useState(0);
  const [hoursPerDay, setHoursPerDay] = useState(
    cookies.get("hoursPerDay") || null
  );
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (userId)
      DBRequest({
        config,
        path: `users/${userId}`,
        onResponse: handleResponse,
      });
  }, [userId, config]);

  function handleResponse(data) {
    if (data.data.hoursPerDay) {
      setHoursPerDay(data.data.hoursPerDay);
    }
  }

  useEffect(() => {
    var newTimeToday = secondsIn ? secondsIn / 60 + 0 : 0;
    if (workdays[currentKey]) {
      workdays[currentKey].forEach((time) => {
        const timeDiff = moment(time.timeEnd).diff(moment(time.timeStart), "m");
        newTimeToday += timeDiff;
      });
    }
    setTimeToday(newTimeToday);
    setPercent(newTimeToday / (hoursPerDay * 60) || 0);
  }, [workdays, secondsIn, hoursPerDay]);

  return (
    <CircularProgressbarWithChildren
      key={timeToday}
      value={percent}
      maxValue={1}
      styles={{
        path: {
          stroke: colors.greenAccent[400],
        },
        trail: {
          stroke: "rgba(0, 0, 0, 0.2)",
          strokeLinecap: "butt",
          transform: "rotate(0.25turn)",
          transformOrigin: "center center",
        },
      }}
    >
      <Box className="flex flex-col">
        <Box className="text-xl md:text-3xl text-center">
          {renderHours(timeToday)} h
        </Box>
        <Typography textAlign="center">
          {Math.round(percent * 100)} %
        </Typography>
      </Box>
    </CircularProgressbarWithChildren>
  );
};
