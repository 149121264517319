import { Box, useTheme } from "@mui/material";
import ExoTable from "../../components/exo/ExoTable";
import { useState } from "react";
import useFetch from "../../api/useFetch";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import Header from "../../components/special/Header";
import ExoTableRecipient from "../../components/exo/ExoTableRecipient";
import MoneyLabel from "../../components/label/MoneyLabel";
import { PaymentReminderStatus } from "../../components/special/Status";
import ExtendPaymentReminder from "./ExtendPaymentReminder";

const PaymentReminder = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const { apiData, isLoading } = useFetch("dunning-processes", true);

  const Columns = [
    {
      header: t("Nr"), // header of column
      key: "id", // key of column in row-object
      thAlign: "center", // header text alignment
      tdAlign: "center", // cell text alignment
      width: "140px",
      sortable: true, // enable sort function for column
      cell: ({ row }) => <Box className="monospace">{row.invoice.nr}</Box>, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Status"),
      key: "status",
      thAlign: "center", // header text alignment
      tdAlign: "center", // cell text alignment
      width: "120px",
      sortable: true, // enable sort function for column
      cell: ({ row }) => <PaymentReminderStatus number={row.status} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Title"),
      key: "title",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      hiddenMobile: true,
      cell: ({ row }) => row.invoice.title, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Recipient"),
      key: "recipient",
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      width: "270px",
      sortable: true, // enable sort function for column
      hiddenMobile: true,
      cell: ({ row }) => <ExoTableRecipient row={row.invoice} />, // custom xml for cells in the column. you get the row as object
    },
    {
      header: t("Invoice Sum"),
      key: "amount",
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "auto",
      cell: ({ row }) => <MoneyLabel money={row.invoice.amount} />,
      sortable: true, // enable sort function for column
    },
  ];

  return (
    <>
      <Header title={t("Payment Reminder")} />
      <ExoTable
        data={apiData || { data: [] }}
        isLoading={isLoading}
        columns={Columns}
        extendElement={({ row, onUpdate, onDelete }) => (
          <ExtendPaymentReminder
            row={row}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )}
      />
    </>
  );
};

export default PaymentReminder;
