import { Box, IconButton, Link, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const CardContentLink = ({ title, href }) => {
  return (
    <Box className="flex flex-row gap-2 h-6 items-center">
      <Typography variant="h6">{title}</Typography>
      <Typography variant="h5" className=" monospace">
        {href}
      </Typography>
      <Link href={href}>
        <IconButton>
          <OpenInNewIcon />
        </IconButton>
      </Link>
    </Box>
  );
};
