import { Box } from "@mui/material";
import React from "react";
import ExoAvatar from "./ExoAvatar";

export const ExoTableUser = ({ user }) => {
  return (
    <Box className="flex flex-row justify-start items-center gap-2">
      <ExoAvatar
        picture={user.picture ? user.picture.temporaryUrl : ""}
        name={user.firstName + " " + user.lastName}
        size="50px"
      />
      {user.firstName + " " + user.lastName}
    </Box>
  );
};
