import { useParams } from "react-router-dom";
import useFetch from "../../../api/useFetch";
import Header from "../../../components/special/Header";
import { useTranslation } from "react-i18next";
import { ProjectManagementWorkPackageSkeleton } from "./ProjectManagementWorkPackageSkeleton";

export const ProjectManagementWorkPackage = () => {
  const { id } = useParams();
  const { apiData, setApiData } = useFetch(`work-packages/${id}`);

  const { t } = useTranslation();
  if (!apiData) return <ProjectManagementWorkPackageSkeleton />;
  return (
    <>
      <Header title={apiData.data.title} />
    </>
  );
};
