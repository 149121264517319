import { useTheme, Box, Skeleton, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import { tokens } from "../../global/theme/tokens";
import DBSearch from "../special/DBSearch";
import ExoTable from "../exo/ExoTable";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";

const SelectContractTemplate = ({ onSelect, type }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  // request
  const [presets, setPresets] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: `contractpresets?category[eq]=${type}`,
      method: API_GET,
      onResponse: setPresets,
      onLoading: setIsLoading,
    });
  }, [config]);

  // tabel
  const columns = [
    {
      header: t("Nr"), // header of column
      key: "id", // key of column in row-object
      thAlign: "center", // header text alignment
      tdAlign: "center", // cell text alignment
      sortable: true, // enable sort function for column
      width: "50px", // width of column
    },
    {
      header: t("Title"), // header of column
      key: "title", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
    },
    {
      header: "", // header of column
      key: "id", // key of column in row-object
      thAlign: "center", // header text alignment
      tdAlign: "center", // cell text alignment
      sortable: true, // enable sort function for column
      width: "100px", // width of column
      cell: ({ row }) => (
        <Button variant="contained" onClick={() => onSelect(row)}>
          {t("Select")}
          <ChevronRightIcon />
        </Button>
      ),
    },
  ];

  return (
    <Box className=" flex flex-col gap-2 h-full overflow-y-auto pr-2">
      <ExoTable columns={columns} data={presets} isLoading={isLoading} />
    </Box>
  );
};

export default SelectContractTemplate;
